import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  Paper,
  Stack,
  TextField,
  Checkbox,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const MockTest = () => {
  const navigate = useNavigate();
  const [mocktest, setMockTest] = useState([]);
  const [filteredMockTest, setFilteredMockTest] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchMockTest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-mock-test`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseData = await response.json();
        console.log(responseData);
        setMockTest(responseData?.data);
        setFilteredMockTest(responseData?.data);
        console.log(mocktest?.name);
      } catch (error) {
        console.log(error);
      }
    };
    fetchMockTest();
  }, []);

  const handleSearchChange = (e) => {
    setSearchValue(e.target?.value);
    setFilteredMockTest(
      mocktest?.filter((item) =>
        item?.title?.toLowerCase()?.includes(e.target?.value?.toLowerCase())
      )
    );
    setPage(0);
    if (e.target?.value === null || e.target?.value === "") {
      setFilteredMockTest(mocktest);
    }
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPageRows = mocktest
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map((user) => user.id);
      setSelectedRows(currentPageRows);
      // console.log(currentPageRows);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };
  const handleRowSelect = (userId) => {
    if (selectedRows.includes(userId)) {
      setSelectedRows(selectedRows.filter((id) => id !== userId));
    } else {
      setSelectedRows([...selectedRows, userId]);
    }
  };

  const handleDeleteSelected = async () => {
    const token = localStorage.getItem("token");
    console.log([...selectedRows]);

    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-mocktest`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ids: [...selectedRows] }),
      }
    );
    const fullres = res.json();
    toast.success("Delete successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1500);

    // console.log(res);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

   const handleDelete = async () => {
     const token = localStorage.getItem("token");
     try {
       const res = await fetch(
         `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-mock-test/${selectedRow.id}`,
         {
           method: "DELETE",
           headers: {
             "Content-Type": "application/json",
             Authorization: `Bearer ${token}`,
           },
         }
       );
       const fullres = await res.json();
       toast.success("Delete successfully");
       setMockTest(mocktest.filter((item) => item.id !== selectedRow.id));
       setFilteredMockTest(
         filteredMockTest.filter((item) => item.id !== selectedRow.id)
       );
       handleClose();
     } catch (error) {
       console.error("Error deleting mock test:", error);
     }
   };



  return (
    <Page title="Mock Test">
      <Typography className="page-heading">All Mock Test</Typography>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          width: "100%",
          height: "40px",
          // marginBottom: "70px",
          "& label": {
            color: "black",
          },
          "& fieldset": {
            borderColor: "black",
          },
        }}
        justifyContent={"flex-end"}
        alignItems={"center"}
        marginBottom={"1.5rem"}
      >
        {selectedRows.length > 0 && (
          <Box height={"40px"}>
            {/* <Typography>{selectedRows?.length}</Typography> */}
            <Button
              variant="contained"
              color="error"
              sx={{
                height: "40px",
              }}
              onClick={handleDeleteSelected}
              // disabled={handleDeleteButtonDisabled()}
            >
              Delete Selected Rows
            </Button>
          </Box>
        )}
        <TextField
          label="Search Course"
          variant="outlined"
          margin="normal"
          value={searchValue}
          onChange={handleSearchChange}
          sx={{
            width: "300px",
          }}
          // sx={{
          //   width: "300px",
          //   height: "20px",
          //   // marginBottom: "70px",
          //   "& label": {
          //     color: "black",
          //   },
          //   "& fieldset": {
          //     borderColor: "black",
          //   },
          // }}
        />
      </Stack>

      <TableContainer
        component={Paper}
        sx={{ width: "100%", overflowX: "auto" }}
      >
        <Table sx={{ tableLayout: "auto" }}>
          <TableHead style={{ backgroundColor: "#e6e6ff" }}>
            <TableRow>
              <TableCell padding="checkbox" sx={{ width: 50 }}>
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  indeterminate={
                    selectedRows.length > 0 &&
                    selectedRows.length < mocktest.length
                  }
                />
              </TableCell>
              <TableCell sx={{ width: 150 }}>Name</TableCell>
              <TableCell sx={{ width: 150 }}>Category</TableCell>
              <TableCell sx={{ width: 150 }}>Exam Name</TableCell>
              <TableCell sx={{ width: 100 }}>Type</TableCell>
              <TableCell sx={{ width: 100 }}>Result</TableCell>
              <TableCell sx={{ width: 150 }}>Starting Date</TableCell>
              <TableCell sx={{ width: 150 }}>Ending Date</TableCell>
              <TableCell sx={{ width: 100 }}>Language</TableCell>
              <TableCell sx={{ width: 150 }}>Duration (mins)</TableCell>
              <TableCell sx={{ width: 150 }}>Each Question Marks</TableCell>
              <TableCell sx={{ width: 150 }}> Question Count</TableCell>
              <TableCell sx={{ width: 150 }}>Negative Marks</TableCell>
              <TableCell sx={{ width: 100 }}>Add Ques.</TableCell>
              <TableCell sx={{ width: 100 }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredMockTest
                  ?.filter((item) =>
                    item?.title
                      ?.toLowerCase()
                      ?.includes(searchValue.toLowerCase())
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : filteredMockTest
            )?.map((user, index) => (
              <TableRow key={index}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRows.includes(user.id)}
                    onChange={() => handleRowSelect(user.id)}
                  />
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    {user?.title ? user?.title : "N/A"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    {user?.course}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    {user?.exam_name || user?.classes_name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    {user?.mock_test_type == 1 ? "Manual" : "Template"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    {user?.result_type}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    {user?.starting_date
                      ? user.starting_date.split(" ")[0]
                      : "N/A"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    {user?.end_date ? user.end_date.split(" ")[0] : "N/A"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    {user?.language === "en" ? "English" : "Hindi"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    {user?.duration || "N/A"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    {user?.eachQuestionMarks || "N/A"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    {user?.count || "N/A"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    {user?.nagetiveMarking==1? user?.nagetiveMarks||"N/A": "N/A"}
                  </Typography>
                </TableCell>
                <TableCell>
                  {user?.mock_test_type == 1 && (
                    <Button
                      variant="contained"
                      sx={{
                        minWidth: "100%",
                        maxWidth: "100%",
                        alignSelf: "center",
                        background: "#e6e6ff",
                        color: "black",
                        borderRadius: "20px",
                        "&:hover": { background: " #8080ff" },
                      }}
                      onClick={() => {
                        navigate(
                          `/dashboard/mockTest/${user?.title}/${user?.id}`
                        );
                      }}
                    >
                      Add
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      setSelectedRow(user);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        if (selectedRow?.mock_test_type == 1) {
                          navigate(
                            `/dashboard/updateManualTest/${selectedRow?.id}`
                          );
                        } else {
                          navigate(
                            `/dashboard/updateTemplateTest/${selectedRow?.id}`
                          );
                        }
                        handleMenuClose();
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={mocktest?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Page>
  );
};

export default MockTest;
