import React, { useEffect } from "react";
import * as Yup from "yup";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import Avatar from "@mui/material/Avatar";


// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getFileUrl } from "../../../utils/firebaseUtils";

// @mui
import {
    Link,
    Stack,
    IconButton,
    InputAdornment,
    Typography,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Grid,
    // Box,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from '../../../components/Iconify';

import {
    FormProvider,
    RHFTextField,
    // RHFCheckbox,
} from "../../../components/hook-form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useTheme } from '@mui/material/styles';
// import OutlinedInput from '@mui/material/OutlinedInput';

// import Page from '../../../components/Page';
import keyValueTab from "../../../data/tabArray"



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


// function getStyles(name, teacher, theme) {
//     return {
//         fontWeight:
//             teacher.indexOf(name) === -1
//                 ? theme.typography.fontWeightRegular
//                 : theme.typography.fontWeightMedium,
//     };
// }

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const UpdateStaff = () => {
    const navigate = useNavigate();
    // const [focus, setFocused] = React.useState(false);
    // const [resources, setResources] = React.useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [users, usersSet] = React.useState([]);
    const { lectId, batchId, teacherId } = useParams();
    const [loading, setLoading] = useState(false);
    // const [fetchMessage, setFetchMessage] = useState();
    const [starting, setStarting] = React.useState(dayjs("2022-01-01T21:11:54"));
  const [ending, setEnding] = React.useState(dayjs("2022-01-01T21:11:54"));
  const [profilePhotoUrl, setProfilePhotoUrl] = useState();
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [val, setVal] = useState(false);




    const [selectedOption, setSelectedOption] = useState('no');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };



    const [teacher, setteacher] = React.useState([]);
    const theme = useTheme();

    const handleChange = (newValue) => {
        setStarting(newValue);
    };
    const handleChangeTwo = (newValue) => {
        setEnding(newValue);
    };

    const LoginSchema = Yup.object().shape({});

    const defaultValues = {};

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = methods;

    const [file, setFile] = useState();

    // const changeHandler = async (event) => {
    //     setFile(event.target.files[0]);
    // };

    //Getting Existing details
    const [lectureDetails, setLectureDetails] = useState();
    const [value, setValue] = useState();
    // useEffect(() => {
    //     const token = localStorage.getItem("token");
    //     async function fetchUsers() {
    //         const fullResponse = await fetch(
    //             `${process.env.REACT_APP_LIVE_URL}/adminPanel/getPerticularLectureDetails/${lectId}`,
    //             {
    //                 method: "GET",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             }
    //         );
    //         const responseJson = await fullResponse.json();
    //         setLectureDetails(responseJson.data);
    //         setStarting(dayjs(responseJson.data.starting_date));
    //         setEnding(dayjs(responseJson.data.ending_date));
    //         setValue((prev) => ({
    //             ...prev,
    //             lecture_type: responseJson.data?.lecture_type,
    //             lecture_title: responseJson.data?.lecture_title,

    //             description: responseJson.data?.description,
    //         }));
    //     }
    //     fetchUsers();
    // }, []);


    //Old Details
    const [oldValue, setOldValue] = useState();
    const [teacherDetails, setTeacherDetails] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-admin-by-id/${teacherId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setTeacherDetails(responseJson.data);
            settingValues(responseJson.data)
            check(responseJson.data)
        }

        fetchUsers();
    }, []);



    // const { teacherId } = useParams()

    //Fetching Data

    const [users1, usersSet1] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-admin-by-id/${teacherId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            usersSet1(responseJson.data);

            check1(responseJson.data)
        }

        fetchUsers();
    }, []);
    let helperArray1 = []
    const check1 = (tabsDetails) => {
        setteacher(tabsDetails?.accessToContent)
        helperArray1 = keyValueTab?.keyValueTab?.map((item, index) => {
          // console.log(item?.value);
            if (teacher?.includes(item?.value)) {
                return item?.label;
            }

        })
        // console.log('line218', helperArray1)
    }

    const handleChange1 = (event) => {
        const {
            target: { value },
        } = event;
        setteacher(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const settingValues = (data) => {
        // console.log(data)
        setOldValue((prev) => ({
          ...prev,
          name: data?.name,
          email: data?.email,
          role: data?.role,
          password: data?.password,
          qualification: data?.qualification,
          is_active: data?.is_active,
          mobileNumber: data?.mobileNumber,
          deleteAccess: String(data?.deleteAccess),
          access: data?.access,
          accessToContent: data?.accessToContent,
          profilePhoto: data?.profilePhoto || profilePhotoUrl, 
        }));
    }
    

    //Submit Function


    const onSubmit = async (data) => {
        
        setLoading(true);

        if (selectedOption === 'no') {
            delete oldValue.password;
        }

        let obj = { ...oldValue,  accessToContent:teacher }
      console.log("this is object", obj)
      
      if (profilePhotoUrl) {
        obj.profilePhoto = profilePhotoUrl;
      }
        
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                "content-type": 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
        };
        axios
            .put(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-admin/${teacherId}`, obj, config)
            .then((response) => {

                if (response.data.response_code === 200) {
                    reset();
                    setLoading(false);
                    toast.success(response?.data?.message);

                    setTimeout(() => {
                        navigate(`/dashboard/staff`);
                        // window.location.reload();
                    }, 1500);
                } else {
                    setLoading(false);
                    toast.error(response.data.message);
                }
            })
            .catch((e) => {
                //console.log(e);
                toast.error(e);
                setLoading(false)
            });

        
    };

    //Getting Batch
    React.useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
          const res = await fetch(
            `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-subjects`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          
          const responseJson = await res.json();
          usersSet(responseJson?.data);

            // setLoading(false)
        }
        fetchUsers();
    }, []);

    const onChange2 = (e) => {
        const { name, value } = e.target;
        setOldValue((prev) => ({ ...prev, [name]: value }));
    };

    const [batchDetails, setBatchDetails] = useState()
    const [batch, setbatch] = useState([]);
    const [batchid, setBatchId] = useState()
    const [role, setRole] = useState('')
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-courses`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            // console.log(responseJson?.data)
            setBatchDetails(responseJson.data);
        }
        fetchUsers()
    }, [batchid])


    let helperArray = []
    const [assignedBatches, setAssignedBatches] = useState()
    const check = (tabsDetails) => {
        helperArray = tabsDetails?.BatchesTeacherIn?.map((item, index) => item?.id)
        setAssignedBatches(helperArray)

        // helperArray = tabsDetails?.BatchesTeacherIn?.map((item, index) => {
        //     if (assignedBatches?.includes(item?.value)) {
        //         return item?.batch_name;
        //     }

        // })
    }
    const handleChangeAssignBatch = (event) => {
        const {
            target: { value },
        } = event;
        setAssignedBatches(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
  };
  

  const changeHandler = async (event) => {
    setFile(event.target?.files);

    // Generate the file URL
    const url = await getFileUrl(event.target?.files[0]);

    // Update the state with the new URL
    setProfilePhotoUrl(url);

    // Validate file type
    const fileName = document.getElementById("file").value.toLowerCase();
    if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
      setRestrict("");
    } else {
      setRestrict("JPG Or PDF");
    }
  };


    return (
      <Grid direction="row" pl={5}>
        <Typography variant="h2" ml={2}>
          Update Staff Details
        </Typography>
        {/* <Typography variant="h6" ml={2} mb={4}>
            Enter the below required details to update a lecture
        </Typography> */}

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} mt={4} sx={{ width: "70%" }}>
            <Stack>
              <label style={{ fontWeight: "500", margin: "0.5rem" }}>
                Name
              </label>
              <RHFTextField
                name="name"
                value={oldValue?.name}
                onChange={onChange2}
              />
            </Stack>
            <Stack>
              <label style={{ fontWeight: "500", margin: "0.5rem" }}>
                Email
              </label>
              <RHFTextField
                name="email"
                value={oldValue?.email}
                onChange={onChange2}
              />
            </Stack>
            <Stack>
              <label style={{ fontWeight: "500", margin: "0.5rem" }}>
                Mobile Number
              </label>
              <RHFTextField
                name="mobileNumber"
                type="number"
                value={oldValue?.mobileNumber}
                onChange={onChange2}
              />
            </Stack>
            <Stack>
              <label style={{ fontWeight: "500", margin: "0.5rem" }}>
                Role
              </label>
              <RHFTextField
                name="role"
                label={oldValue?.role}
                select
                value={oldValue?.role}
                disabled={oldValue?.role === "teacher"}
                onChange={onChange2}
              >
                <MenuItem value="teacher" onClick={() => setRole("teacher")}>
                  Teacher
                </MenuItem>
                <MenuItem value="subadmin" onClick={() => setRole("subadmin")}>
                  Sub-Admin
                </MenuItem>
                <MenuItem
                  value="moderator"
                  onClick={() => setRole("moderator")}
                >
                  Moderators
                </MenuItem>
              </RHFTextField>
            </Stack>

            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="file"
                onChange={changeHandler}
                id="file"
                label="File"
                error={!file && val}
                helperText="Image Only"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                onClick={() => setPdf(true)}
                inputProps={{ accept: "image/*" }}
                type={pdf || focus ? "file" : "text"}
              />
            </Stack>

            {oldValue?.profilePhoto && (
              <Stack direction="column" spacing={2} alignItems="flex-start">
                <Typography variant="body2" color="textSecondary">
                  Previous Profile Photo Link: {oldValue.profilePhoto}
                </Typography>
              </Stack>
            )}

            {oldValue?.role !== "teacher" && (
              <>
                <label>edit password</label>
                <label>
                  <input
                    type="radio"
                    value="yes"
                    checked={selectedOption === "yes"}
                    onChange={handleOptionChange}
                  />
                  Yes
                </label>

                <label>
                  <input
                    type="radio"
                    value="no"
                    checked={selectedOption === "no"}
                    onChange={handleOptionChange}
                  />
                  No
                </label>

                {selectedOption === "yes" ? (
                  <RHFTextField
                    name="password"
                    // label="Password"
                    type={showPassword ? "text" : "password"}
                    value={oldValue?.password}
                    onChange={onChange2}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <></>
                )}

                {/* <Stack direction="row">
                    <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Assign Subjects</InputLabel>
                    <Select
                        // label    ="Name"
                        // id="demo-multiple-name"
                        // labelId="test-select-label"
                        label=""
                        multiple
                        name='Subject'
                        value={teacher}
                        onChange={handleChange}

                        sx={{ width: '100%', color: 'black', zIndex: 9 }}
                        MenuProps={MenuProps}
                    >

                        {users?.map((name) => (
                            <MenuItem
                                key={name}
                                value={name.id}
                                style={getStyles(name, teacher, theme)}
                            >
                                {name.title}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack> */}
                {/* <Stack direction="row">
                    <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Assign Tabs</InputLabel>
                    <Select

                        label=""
                        multiple
                        name='TeacherAccess'
                        value={accessToContent}
                        onChange={handleChangeAccess}

                        sx={{ width: '100%', color: 'black', zIndex: 9 }}
                        MenuProps={MenuProps}
                    >

                        {tabsArray?.map((item, i) => (
                            <MenuItem
                                key={i}
                                value={item.name}
                                style={getStyles(item, accessToContent, theme)}
                            >
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack> */}
                {/* <Stack direction="row" spacing={3}>


                    <RHFTextField name='qualification' helperText='Add Only Valid Qualification'
                        value={oldValue?.qualification}
                        onChange={onChange2}
                    />



                </Stack> */}
                {(role == "moderator" || oldValue?.role == "moderator") && (
                  <Stack direction="row">
                    <InputLabel
                      sx={{
                        width: "20%",
                        marginRight: "20px",
                        alignSelf: "center",
                      }}
                    >
                      Assign Batch
                    </InputLabel>
                    <Select
                      // label    ="Name"
                      // id="demo-multiple-name"
                      // labelId="test-select-label"
                      label=""
                      multiple
                      name="assignedBatches"
                      // value={oldValue?.batches?.batch_name}
                      value={assignedBatches}
                      onChange={handleChangeAssignBatch}
                      sx={{ width: "100%", color: "black", zIndex: 9 }}
                      MenuProps={MenuProps}
                    >
                      {batchDetails?.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.id}
                          onClick={() => setBatchId(item?.id)}
                          // style={getStyles(item, batch, theme)}
                        >
                          {item?.batch_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                )}

                <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "90px",
                    alignItems: "center",
                  }}
                >
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Access Level
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="female"
                      onClick={() =>
                        setOldValue((prev) => ({ ...prev, access: "read" }))
                      }
                      checked={oldValue?.access == "read"}
                      control={<Radio />}
                      label="Read Only"
                    />
                    <FormControlLabel
                      value="male"
                      onClick={() =>
                        setOldValue((prev) => ({
                          ...prev,
                          access: "readWrite",
                        }))
                      }
                      checked={oldValue?.access == "readWrite"}
                      control={<Radio />}
                      label="Read & Write "
                    />
                    <FormControlLabel
                      value="other"
                      onClick={() =>
                        setOldValue((prev) => ({
                          ...prev,
                          deleteAccess:
                            oldValue?.deleteAccess === "true"
                              ? "false"
                              : "true",
                        }))
                      }
                      checked={oldValue?.deleteAccess === "true"}
                      control={<Checkbox {...label} />}
                      label="Delete Access"
                    />
                    {/* <FormControlLabel
                            value="disabled"
                            disabled
                            control={<Radio />}
                            label="other"
                        /> */}
                  </RadioGroup>
                </FormControl>
                <RHFTextField
                  name="is_active"
                  label={oldValue?.is_active === "true" ? "Published" : "Draft"}
                  select
                  value={oldValue?.is_active}
                  onChange={onChange2}
                >
                  <MenuItem value={"true"}>Publish</MenuItem>
                  <MenuItem value={"false"}>Draft</MenuItem>
                </RHFTextField>

                <InputLabel
                  sx={{
                    width: "40%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Add Permissions{" "}
                </InputLabel>
                <Select
                  label=""
                  multiple
                  name="teacher"
                  value={teacher}
                  onChange={handleChange1}
                  sx={{ width: "100%", color: "black", zIndex: 9 }}
                  MenuProps={MenuProps}
                >
                  {keyValueTab?.keyValueTab?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name?.value}
                      // style={getStyles(name, teacher, theme)}
                    >
                      {name.label}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            sx={{
              bgcolor: "#e6e6ff",
              color: "black",
              width: "70%",
              marginTop: "5%",
              "&:hover": { background: "#8080ff" },
            }}
          >
            Update
          </LoadingButton>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Grid>
    );
};

export default UpdateStaff;

