import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { getFileUrl } from "../../../utils/firebaseUtils";
import CategoryComponent from "../../../utils/CategoryComponent";
// ----------------------------------------------------------------------

export default function AddPathyakram() {
  const navigate = useNavigate();
  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(true);
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const [languages, setLanguages] = useState();

  //* CATEGORY IMPLEMENTATION useStates *
  const [selectedCategory, setSelectedCategory] = useState("");
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    // lastName: Yup.string().required('Last name required'),
    // category: Yup.string().required('Category is required'),
    // file_url: Yup.string().required('Url  is requiXred'),
    // resource_type: Yup.string().required('Resource Type  is required'),
    // subject: Yup.string().required("Subject"),
    // language: Yup.string().required('Language is required'),
  });

  const defaultValues = {
    title: "",
    // category: '',
    // file: '',
    resource_type: "",
    subject: "",
    language: "",
  };
  const [file, setFile] = useState();

  const changeHandler = async (event) => {
    if (!type) {
      setFile(event.target.files[0]);
      setFileUrl(await getFileUrl(event.target.files[0]));
    } else {
      setFile(event.target.value);
      setFileUrl(event.target.value);
    }
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    if (file) {
      setLoading(true);
      const payload = {
        file_url: fileUrl,
        title: data?.title,
        category: "Pathyakram",
        // subject: data?.subject,
        resource_type: data?.resource_type,
        language: data?.language,
        is_active: "true",
        course: selectedCategory,
        state_exam_id: selectedState,
        board_id: selectedBoard,
        exam_id: centralExam || stateExam,
        class_id: classVal,
      };

      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-syllabus`,
          payload,
          config
        )
        .then((response) => {
          console.log(response.data);
          setPdf(false);
          if (response.data.response_code === 200) {
            toast.success("Syllabus Added");
            setFile(null);
            setFileUrl(null);
            setPdf(false);
            setFocused(false);
            setVal(false);
            setMsg("");
            setLoading(false);
            reset();
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            setLoading(false);
            toast.error(response?.data?.message);
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };
  const [users, usersSet] = useState([]);

  //Setting resource type
  const [type, setType] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      // const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Resources`, {
      //     method: 'GET',
      //     headers: {
      //         'Content-Type': 'application/json',
      //         Authorization: `Bearer ${token}`
      //     },

      // });
      // const responseJson = await fullResponse.json();
      // usersSet(responseJson.data);
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-subjects`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resSubject = await res.json();
      setSubject(resSubject.data);
      console.log("Subject's List", resSubject);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch languages");
      }
      const data = await response.json();
      setLanguages(data.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Syllabus</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />

            {/* <RHFTextField name="resource_type" label="Resource type">
                <MenuItem onClick={() => setType(true)} value="link">
                  Link
                </MenuItem>
                <MenuItem onClick={() => setType(false)} value="file">
                  File
                </MenuItem>
              </RHFTextField> */}

            <Stack direction="row" spacing={3}>
              <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
                <RHFTextField
                  name="file"
                  onChange={changeHandler}
                  error={!file && val}
                  label={type ? "Link" : "PDF"}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  onClick={() => setPdf(true)}
                  type={pdf || focus ? (type ? "url" : "file") : "text"}
                  inputProps={{ accept: "application/pdf" }}
                  helperText="Pdf Files Only"
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                />
                {!file && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      marginTop: "5px",
                      marginLeft: "10px",
                      color: "red",
                    }}
                  >
                    {msg}
                  </Typography>
                )}
              </Stack>

              {/* //!temperory
                 <RHFTextField
                  name="language"
                  label="language"
                  sx={{ width: "100%" }}
                  select
                >
                  {languages?.map((language, index) => (
                    <MenuItem key={index} value={language.medium_code}>
                      {language.medium_name}
                    </MenuItem>
                  ))}
                </RHFTextField> */}
            </Stack>

            <CategoryComponent
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              classVal={classVal}
              setClass={setClass}
              centralExam={centralExam}
              setCentralExam={setCentralExam}
              stateExam={stateExam}
              setStateExam={setStateExam}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              selectedBoard={selectedBoard}
              setSelectedBoard={setSelectedBoard}
            />

            <Stack direction="row" spacing={3}>
              {/* <RHFTextField name="category" label="Categroy" select>
                            {users?.map((item, index) => {
                                return <MenuItem key={index} value={item.title}>{item.title}</MenuItem>
                            })}

                        </RHFTextField> */}
              {/* <RHFTextField
                name="subject"
                label="Subject"
                select
                defaultValue=""
              >
                {[
                  subject?.map((sub, i) => {
                    return (
                      <MenuItem key={i} value={sub.id}>
                        {sub.name}
                      </MenuItem>
                    );
                  }),
                ]}
              </RHFTextField> */}
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
