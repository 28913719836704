import React from "react";
import * as Yup from "yup";
import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams, useResolvedPath } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { User } from "../../../context/context";
import Page from "../../../components/Page";
import { useTheme } from "@mui/material/styles";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFileUrl } from "../../../utils/firebaseUtils";
import { set } from "lodash";
import { sub } from "date-fns";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const UpdateTestSeries = () => {
  const navigate = useNavigate();
  // const { setRole } = useContext(User)
  const { testSeriesId } = useParams();
  const [category, setCategory] = React.useState();
  const [type, setType] = React.useState("");
  const [testSeriesDetails, setTestSeriesDetails] = React.useState([]);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategories, setSubCategories] = useState();
  const [subject, setSubject] = useState([]);
  const [languages, setLanguages] = useState([]);

  // const [selectedCategory, setSelectedCategory] = useState("");
  // const [subCategories, setSubCategories] = useState([]);
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [states, setStates] = useState();
  const [boards, setBoards] = useState();
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState(null);

  const [selectedState, setSelectedState] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  // const [tags, setTags] = useState([]);

  const streamCategory = [
    {
      id: "class",
      title: "School Exams",
    },
    {
      id: "central-exam",
      title: "Central Exams",
    },
    {
      id: "state-exam",
      title: "State Exams",
    },
  ];

  const handleCategoryChange = async (event) => {
    const selectedCategoryId = event.target.value;
    console.log(selectedCategoryId);
    console.log(event.target.value);
    setStates(null);
    setBoards(null);
    setSubCategories(null);
    setSelectedBoard(null);
    setSelectedState(null);

    setIsCategoryLoading(true);
    setSelectedCategory(selectedCategoryId);

    const token = localStorage.getItem("token");
    switch (selectedCategoryId) {
      case "central-exam":
        const centralExams = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-central-exams`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSubCategories(centralExams?.data?.data);
        break;

      case "state-exam":
        const stateList = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-states`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(stateList?.data?.data);
        setStates(stateList?.data?.data);
        break;

      case "class":
        const boardsList = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-boards`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(boardsList?.data?.data)
        setBoards(boardsList?.data?.data);
        break;

      default:
        break;
    }
    // Fetch subcategories based on the selected category
    setIsCategoryLoading(false);
  };

  const handleStateChange = async (e) => {
    setIsCategoryLoading(true);
    setSelectedState(e.target.value);
    const currentState = e.target.value;
    setSubCategories(null);
    const stateExamsList = await axios.post(
      `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-state-exams`,
      { state_exam_id: currentState }
    );
    console.log(stateExamsList.data);
    setSubCategories(stateExamsList.data?.data);
    setIsCategoryLoading(false);
  };

  const handleBoardChange = async (e) => {
    setIsCategoryLoading(true);
    const currentBoard = e.target.value;
    setSelectedBoard(e.target.value);
    setSubCategories(null);
    const boardExamsList = await axios.post(
      `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-classes`,
      { board_id: currentBoard }
    );
    console.log(boardExamsList.data);
    setSubCategories(boardExamsList.data?.data);
    setIsCategoryLoading(false);
  };

  const handleSubCategoryChange = (e) => {
    setClass(null);
    setStateExam(null);
    setCentralExam(null);

    if (boards) setClass(e.target.value);
    else if (states) setStateExam(e.target.value);
    else setCentralExam(e.target.value);

    console.log("boards", classVal);
    console.log("states", stateExam);
    console.log("central", centralExam);
  };

  const LoginSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-testseries/${testSeriesId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      console.log("test series data", responseJson?.data);
      setTestSeriesDetails(responseJson.data);
      setCategory(responseJson.data?.course);
      // setSubCategories(responseJson.data?.subCategory);
      setStates(responseJson.data?.state_exam_name);
      setStateExam(responseJson.data?.exam_name);
      setBoards(responseJson.data?.boards_name);
      setClass(responseJson.data?.classes_name);

      addDetails(responseJson.data);
    }

    fetchUsers();
  }, []);
  //console.log(testSeriesDetails)

  //Helper Function

  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {
      const languageData = await axios.post(
        `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (languageData?.status !== 200)
        throw new Error("Failed to fetch languages");
      console.log(languageData?.data?.data);
      setLanguages(languageData?.data?.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);

  const addDetails = (TestSeriesDetails) => {
    // let arr2 = []
    // TestSeriesDetails?.teacher?.map((item) => { arr2.push(item?.id) })
    // setteacher(arr2)
    setValue((prev) => ({
      ...prev,
      ...TestSeriesDetails,
    }));
  };
  // //console.log(value0)
  //Teacher List
  const [teacher, setteacher] = useState([]);
  const [allteachers, teacherSet] = useState([]);
  const [val, setVal] = useState(false);
  // useEffect(() => {
  //     const token = localStorage.getItem("token");
  //     async function fetchUsers() {
  //         const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-admins`, {
  //             method: 'GET',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 Authorization: `Bearer ${token}`
  //             },

  //         });
  //         const responseJson = await fullResponse.json();
  //         console.log(responseJson?.data);
  //         teacherSet(responseJson.data);
  //         setLoading(false)

  //     }

  //     fetchUsers();
  // }, []);

  // const handleChange = (event) => {
  //     const {
  //         target: { value },
  //     } = event;
  //     setteacher(
  //         // On autofill we get a stringified value.
  //         typeof value === 'string' ? value.split(',') : value,
  //     );
  // };

  //Date
  const [focus, setFocused] = React.useState(false);
  const [showStartingDate, setShowStartingDate] = React.useState(false);

  //Stream

  //Banner
  const [file, setFile] = useState();
  const [msg, setMsg] = useState("");
  const [focusThree, setFocusedThree] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState(false);
  const changeHandler = async (event) => {
    const url = await getFileUrl(event.target?.files[0]);
    setFile(url);
  };

  //Adding Details
  const onSubmit = async () => {
    console.log(value, file);

    setLoading(true);

    const payload = {
      ...value,
      banner_url: file || value?.banner_url,
    };
    let token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .put(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-testseries/${testSeriesId}`,
        payload,
        config
      )
      .then((response) => {
        console.log(response);

        setShowStartingDate(false);
        if (response.data?.response_code === 200) {
          toast.success("Test Series Details updated");
          setShowBanner(false);
          setLoading(false);
          setTimeout(() => {
            navigate("/dashboard/test");
          }, 1000);
          reset();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const [value, setValue] = React.useState({
    name: "",
    charges: "",
    discount: "",
    remark: "",
    Validity: "",
    no_of_test: "",
  });
  const [value2, setValue2] = React.useState("jhbj");
  const onChange2 = (e) => {
    const { name, value } = e.target;
    setValue((prev) => ({ ...prev, [name]: value }));
  };
  // onChange2()
  //console.log(value)
  return (
    <>
      <Page title="Test Series">
        <Stack spacing={3} ml={5}>
          <Typography variant="h3">Update Test series</Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "80%" }}>
              {/* {console.log(value)} */}
              <RHFTextField
                name="name"
                value={value.name}
                label="Name"
                onChange={onChange2}
              />

              <Stack direction={"row"} spacing={3}>
                <RHFTextField
                  name="category"
                  // label="Category"
                  sx={{ width: "50%" }}
                  // select
                  value={category}
                  disabled

                  // onChange={handleCategoryChange}
                />

                {states && (
                  <RHFTextField
                    name="states"
                    //   label="State"
                    sx={{ width: "50%" }}
                    // select
                    value={states}
                    disabled

                    // onChange={handleStateChange}
                  />
                )}

                {boards && (
                  <RHFTextField
                    name="boards"
                    // label="Boards"
                    sx={{ width: "50%" }}
                    // select
                    value={boards}
                    disabled

                    // onChange={handleBoardChange}
                  />
                )}

                <RHFTextField
                  name="subCategory"
                  // label="Subcategory"
                  sx={{ width: "50%" }}
                  value={stateExam || classVal}
                  disabled

                  // select
                  // onChange={handleSubCategoryChange}
                />
              </Stack>
              {/*
                  <Stack direction={"row"} spacing={3}>
                    <RHFTextField
                      name="category"
                      label="category"
                      sx={{ width: "50%" }}
                      select
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                    >
                      {streamCategory?.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </RHFTextField>

                    <RHFTextField
                      name="subCategory"
                      label="subcategory"
                      sx={{ width: "50%" }}
                      select
                    >
                      {subCategories.map((subcategory, index) => (
                        <MenuItem key={index} value={subcategory.title}>
                          {subcategory.title}
                        </MenuItem>
                      ))}
                    </RHFTextField>
                  </Stack>
                      */}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                {/* <RHFTextField
                                // labelId="demo-simple-select-label"


                                label="Category"
                                name='category'
                                select

                                sx={{ width: '100%' }}
                            >
                                {users?.map((item, i) => { return <MenuItem key={i} value='{item.title}'>{item.title}</MenuItem> })}

                            </RHFTextField> */}
                {/*
                  <RHFTextField
                    select
                    name="exam_type"
                    label={"Exam Type"}
                    sx={{ width: "100%" }}
                    value={testSeriesDetails?.exam_type}
                  >
                    <MenuItem value="Prelims">Prelims</MenuItem>
                    <MenuItem value="Mains">Mains</MenuItem>
                    <MenuItem value="Interview">Interview</MenuItem>
                  </RHFTextField>
                          */}
              </Stack>

              <Stack direction="row" spacing={3}>
                <RHFTextField
                  name="starting_date"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  value={value?.starting_date}
                  label={`${value?.starting_date} (Starting Date)`}
                  onClick={() => setShowStartingDate(true)}
                  onChange={onChange2}
                  type={showStartingDate || focus ? "datetime-local" : "text"}
                />
                <Stack direction="column" sx={{ width: "100%" }}>
                  <RHFTextField
                    name="file"
                    error={!file && val}
                    onChange={changeHandler}
                    onFocus={() => setFocusedThree(true)}
                    onBlur={() => setFocusedThree(false)}
                    helperText="Image with a size of 712x356 pixels, maintaining a 2:1 aspect ratio (jpeg,jpg,png,webp)"
                    InputProps={{
                      classes: {
                        input: "CustomTextField",
                      },
                    }}
                    label="Banner"
                    onClick={() => setShowBanner(true)}
                    type={showBanner || focusThree ? "file" : "text"}
                  />
                  {/* {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>Banner Requied</Typography>} */}
                  {!file && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      {msg}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <Stack direction="row" spacing={3}>
                <RHFTextField
                  name="charges"
                  label="Charges"
                  value={value?.charges}
                  type="number"
                  onChange={onChange2}
                />
                <RHFTextField
                  name="discount"
                  label="Discount in %"
                  value={value?.discount}
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 100,
                      min: 0,
                    },
                  }}
                  onChange={onChange2}
                />
                <RHFTextField
                  name="language"
                  label={`${value?.language} (language code)`}
                  disabled
                  value={String(value?.language)}
                  onChange={onChange2}
                  sx={{ width: "100%" }}
                  select
                >
                  {languages?.map((language, index) => (
                    <MenuItem key={index} value={String(language?.medium_code)}>
                      {language?.medium_name}
                    </MenuItem>
                  ))}
                </RHFTextField>

                {/*
                  <RHFTextField
                    name="stream"
                    label={testSeriesDetails?.stream}
                    select
                  >
                    {streamCategory?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.title}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </RHFTextField> */}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                {/* <RHFTextField name="no_of_test" label='No of Test' value={value?.no_of_test} type='number' onChange={onChange2} /> */}
                <RHFTextField
                  name="Validity"
                  value={value?.Validity}
                  onChange={onChange2}
                  label="Validity (in Day)"
                />
                <RHFTextField
                  name="remark"
                  value={value?.remark}
                  onChange={onChange2}
                  label="Remark"
                />
                <RHFTextField
                  name="is_active"
                  label={`${
                    value?.is_active == "true" ? "Published" : "Draft"
                  } (Status)`}
                  value={value?.is_active === "true" ? "true" : "false"}
                  sx={{ width: "100%" }}
                  select
                  type="checkbox"
                  onChange={onChange2}
                >
                  <MenuItem value={"true"}>Publish</MenuItem>
                  <MenuItem value={"false"}>Draft</MenuItem>
                </RHFTextField>
              </Stack>

              {/*
                <RHFTextField
                  // label="Description"
                  name="description"
                  value={value?.description}
                  onChange={onChange2}
                  multiline
                  rows={4}
                />
             */}
              <RHFTextEditor
                name="description"
                value={value?.description}
                label={"Description"}
                onChange={(value) => {
                  setValue((prev) => ({ ...prev, description: value }));
                }}
              />

              {/* <RHFTextField
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                /> */}
            </Stack>

            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <RHFCheckbox name="remember" label="Remember me" />
                <Link variant="subtitle2" underline="hover" sx={{ color: '#e6e6ff' }}>
                    Forgot password?
                </Link>
            </Stack> */}

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                width: "80%",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
            >
              Update
            </LoadingButton>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* <Typography sx={{ color: 'red', textAlign: 'center' }}>{fetchMessage}</Typography> */}
        </Stack>
      </Page>
    </>
  );
};

export default UpdateTestSeries;
