import {
  Card,
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  InputLabel,
  Container,
  TableContainer,
  TablePagination,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import Scrollbar from "../../../components/Scrollbar";
import React, { useEffect, useState } from "react";
import { fShortenNumber } from "../../../utils/formatNumber";
import Iconify from "../../../components/Iconify";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import InfoCard from "../../../components/batch/InfoCard";
import SyncLoader from "react-spinners/SyncLoader";
import UserListHead from "./UserListHead";
import UserListToolbar from "./UserListToolbar";
import CourseTableCard from "./Components/courseTableCard";
import SeriesTableCard from "./Components/SeriesTableCard";
import SubscriptionTableCard from "./Components/SubscriptionTableCard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StudentBatches = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("courses");
  const [batchInfo, setBatchInfo] = useState([]);
  const [filteredBatchInfo, setFilteredBatchInfo] = useState([]);
  const [testSeriesInfo, setTestSeriesInfo] = useState([]);
  const [filteredTestSeries, setFilteredTestSeries] = useState([]);
  const [subscriptionInfo, setSubscriptionInfo] = useState([]); // New state for subscription info
  const [filteredSubscriptionInfo, setFilteredSubscriptionInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterName, setFilterName] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - batchInfo?.length) : 0;

  // Fetching data for courses, test series, and subscriptions
  useEffect(() => {
    const token = localStorage.getItem("token");

    async function fetchCourses() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-courses`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse?.json();
      setBatchInfo(responseJson?.data);
      setLoading(false);
      setFilteredBatchInfo(responseJson?.data);
    }

    async function fetchTestSeries() {
      try {
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-purchased-testseries`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setTestSeriesInfo(responseJson?.data);
        setLoading(false);
        setFilteredTestSeries(responseJson?.data);
      } catch (error) {
        console.error("Error fetching test series information:", error);
      }
    }

    async function fetchSubscriptions() {
      try {
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-purchased-subscription`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setSubscriptionInfo(responseJson?.data);
        setLoading(false);
        setFilteredSubscriptionInfo(responseJson?.data);
      } catch (error) {
        console.error("Error fetching subscription information:", error);
      }
    }

    fetchCourses();
    fetchTestSeries();
    fetchSubscriptions();
  }, []);

  // Handle search input changes
  const handleSearchInputChange = (event) => {
    const searchValue = event.target?.value;
    setSearchInput(searchValue);
    if (searchValue === "") {
      setFilteredBatchInfo(batchInfo);
      setFilteredTestSeries(testSeriesInfo);
      setFilteredSubscriptionInfo(subscriptionInfo);
    } else {
      const filteredBatches = batchInfo?.filter(
        (item) =>
          item?.batch_name &&
          item?.batch_name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredBatchInfo(filteredBatches);

      const filteredTestSeries = testSeriesInfo?.filter(
        (item) =>
          item?.name &&
          item?.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredTestSeries(filteredTestSeries);

      const filteredSubscriptions = subscriptionInfo?.filter(
        (item) =>
          item?.name &&
          item?.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredSubscriptionInfo(filteredSubscriptions);
    }
  };

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <>
          <Grid
            container
            pl={0}
            pr={3}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <FormControl variant="standard">
                <Select
                  style={{
                    fontSize: "1.875rem",
                    lineHeight: "2.25rem",
                    fontWeight: "600",
                    letterSpacing: "0.025em",
                    margin: "2rem",
                    borderStyle: "none",
                    outline: "2px solid transparent",
                    minWidth: "23rem",
                  }}
                  value={type}
                  onChange={(e) => setType(e.target?.value)}
                >
                  <MenuItem value="courses" style={{ color: "#8080ff" }}>
                    Courses Purchased
                  </MenuItem>
                  <MenuItem value="testSeries" style={{ color: "#8080ff" }}>
                    Test Series Purchased
                  </MenuItem>
                  <MenuItem value="subscription" style={{ color: "#8080ff" }}>
                    Subscription Purchased
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {type === "courses" && <CourseTableCard batchInfo={batchInfo} />}
          {type === "testSeries" && (
            <SeriesTableCard testSeriesInfo={testSeriesInfo} />
          )}
          {type === "subscription" && (
            <div>
              <SubscriptionTableCard subscriptionInfo={subscriptionInfo} />
              <ToastContainer
                position="bottom-right" 
                style={{ zIndex: 9999 }} 
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default StudentBatches;
