import storage from "./firebase";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const getFileUrl = async (file) => {
  try {
    const storage = getStorage();
    const date = new Date();
    const spaceRef = ref(storage, `${date.getTime()}${file.name}`);
    await uploadBytes(spaceRef, file);
    const fileUrl = await getDownloadURL(spaceRef);
    console.log("File URL:", fileUrl);
    return fileUrl;
  } catch (error) {
    console.error("Error uploading file:", error);
    return null;
  }
};
