import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  MenuItem,
  TextField,
  TableHead,
  Box,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
import { Link, useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import axios from "axios";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LinkIcon from "@mui/icons-material/Link";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// for Dialog box
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { CSVLink } from "react-csv"; // for CSV file download
import { getFileUrl } from "../../../utils/firebaseUtils";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    id: "",
    label: "S.No",
    alignRight: false,
    editable: true,
  },
  { id: "title", label: "Title", alignRight: false, editable: true },
  // { id: 'category', label: 'Category', alignRight: false, editable: true },
  { id: "course", label: "Category", alignRight: false },
  { id: "exam name", label: "Exam Name/ Class", alignRight: false },
  { id: "file_url", label: "File ", alignRight: false },
  { id: "language", label: "Language", alignRight: false },
  { id: "is_active", label: "Status", alignRight: false },
  { id: "is_active", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function PathyakramTable() {
  // Code for handling Dialog box
  const [download, setDownload] = useState(true);
  const [errorOTP, setErrorOtp] = useState();
  const [open, setOpen] = useState(false);
  const [languages, setLanguages] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPageRows = users
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map((user) => user.id);
      setSelectedRows(currentPageRows);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };
  const handleRowSelect = (userId) => {
    if (selectedRows.includes(userId)) {
      setSelectedRows(selectedRows.filter((id) => id !== userId));
    } else {
      setSelectedRows([...selectedRows, userId]);
    }
  };
  const handleDeleteButtonDisabled = () => {
    return selectedRows.length === 0;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const sendOtp = () => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      action: "Exporting All Pathyakram Data",
    };

    // axios
    //   .post(
    //     `${process.env.REACT_APP_LIVE_URL}/adminTeacher/send-otp`,
    //     data,
    //     config
    //   )
    //   .then((response) => {})
    //   .catch((e) => {
    //     //console.log(e);
    //   });
  };
  const verifyOtp = () => {
    //console.log(document.getElementById('name').value);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      otp: document.getElementById("name").value,
    };

    // axios
    //   .post(
    //     `${process.env.REACT_APP_LIVE_URL}/adminTeacher/verify-otp`,
    //     data,
    //     config
    //   )
    //   .then((response) => {
    //     // //console.log(response);
    //     if (response.data?.status == false) {
    //       setErrorOtp("Invalid Otp");
    //     } else {
    //       setDownload(false);
    //       setErrorOtp("Successfully Verified");
    //     }
    //   })
    //   .catch((e) => {
    //     //console.log(e);
    //   });
  };

  const [page, setPage] = useState(0);
  // const [users, usersSet] = React.useState([]);
  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState();

  //Getting Pathyakram
  const [isActiveValue, setIsActiveValue] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-syllabus`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  let helperArray = [];
  filteredUsers?.map((item) =>
    helperArray.push({
      ID: item?.id,
      File_url: item?.file_url?.fileLoc,
      Title: item?.title,
      Created_at: item?.created_at,
      Is_active: item?.is_active,
      Language: item?.language,
      Resource_type: item?.resource_type,
      Subject: item?.subject,
    })
  );

  const isUserNotFound = filteredUsers.length === 0;
  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  // //console.log(updatedValues)

  const [file, setFile] = useState();

  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch languages");
      }
      const data = await response.json();
      setLanguages(data?.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);

  const handleDeleteSelected = async () => {
    const token = localStorage.getItem("token");
    console.log([...selectedRows]);
    // const res = await axios.delete(
    //   `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-banner`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   },
    //   { ids: [...selectedRows] }
    // );
    // console.log(res);
    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-syllabus`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ids: [...selectedRows] }),
      }
    );
    const fullres = res.json();
    toast.success("Delete successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1500);

    console.log(res);
  };

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Syllabus">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
                {selectedRows.length > 0 && (
                  <Box height={"40px"}>
                    {/* <Typography>{selectedRows?.length}</Typography> */}
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        height: "40px",
                      }}
                      onClick={handleDeleteSelected}
                      // disabled={handleDeleteButtonDisabled()}
                    >
                      Delete Selected Rows
                    </Button>
                  </Box>
                )}

                {/* <ExportCSV csvData={helperArray} fileName='Pathyakram Data' /> */}

                {/* <Button
                  variant="outlined"
                  onClick={() => {
                    handleClickOpen();
                    sendOtp();
                  }}
                >
                  Export
                </Button> */}
                <Dialog open={open} onClose={handleClose}>
                  {download ? (
                    <>
                      <DialogTitle>OTP Varification</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          OTP is send successfully to your regisered e-mail.
                        </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="OTP Number"
                          type="number"
                          fullWidth
                          variant="standard"
                        />
                        <DialogContentText
                          sx={{
                            color: "red",
                            fontSize: "12px",
                            padding: "3px",
                          }}
                        >
                          {errorOTP}
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={verifyOtp}>Verify</Button>
                      </DialogActions>
                    </>
                  ) : (
                    <>
                      <DialogTitle>Download File</DialogTitle>
                      <DialogContent>
                        <CSVLink
                          data={helperArray}
                          filename="AllPathyakram.csv"
                        >
                          Download
                        </CSVLink>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            handleClose();
                            setDownload(true);
                          }}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </>
                  )}
                </Dialog>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    {/* <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    /> */}
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                            indeterminate={
                              selectedRows.length > 0 &&
                              selectedRows.length < users.length
                            }
                          />
                        </TableCell>
                        {TABLE_HEAD.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.alignRight ? "right" : "left"}
                          >
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => {
                          let {
                            title,
                            course,
                            exam_name,
                            classes_name,
                            file_url,
                            language,
                            is_active,
                            id,
                            category,
                            resource_type,
                          } = row;
                          const isItemSelected = selected.indexOf(title) !== -1;

                          //File handling
                          const changeHandler = async (event) => {
                            setFile(await getFileUrl(event.target.files[0]));
                          };

                          const onChange = (e, row, id) => {
                            const { name, value } = e.target;
                            if (name == "is_active") {
                              //console.log('came')
                              setIsActiveValue(true);
                            }
                            setUpdatedValues((prev) => {
                              return { ...prev, [name]: value };
                            });
                          };
                          {
                            /* //console.log(updatedValues) */
                          }
                          const handleUpdate = async () => {
                            // setLoading(true);

                            //console.log('Category' + updatedValues?.category)
                            //console.log(updatedValues)
                            let data;
                            // console.log(title, file_url, subject, language, is_active)
                            if (!updatedValues.category) {
                              data = {
                                ...updatedValues,
                                category: category?.title,
                              };
                            } else {
                              data = { ...updatedValues };
                            }
                            // console.log("THis is data bhai ", data)
                            makeReq(data);
                          };

                          const makeReq = async (data) => {
                            const token = localStorage.getItem("token");
                            const authToken = token;
                            const formData = new FormData();
                            let payload = { ...data };
                            payload = {
                              ...payload,
                              file_url: file || file_url,
                              title: data?.title || title,
                              category: "Pathyakram",
                              // subject: data?.subject || subject,
                              language: data?.language || language,
                              is_active:
                                (data?.is_active && String(data?.is_active)) ||
                                String(is_active),
                            };
                            formData.append("file_url", file || file_url);

                            formData.append("title", data?.title || title);
                            // console.log("this is title data sent", data?.title || title);

                            formData.append("category", "Pathyakram");
                            if (resource_type === "file") {
                              payload = { ...payload, resource_type: "file" };
                              formData.append("resource_type", "file");
                            }
                            if (resource_type === "link") {
                              payload = { ...payload, resource_type: "link" };
                              formData.append("resource_type", "link");
                            }

                            // formData.append(
                            //   "subject",
                            //   data?.subject || subject
                            // );

                            formData.append(
                              "language",
                              data?.language || language
                            );

                            formData.append(
                              "is_active",
                              data?.is_active || is_active
                            );

                            const config = {
                              headers: {
                                // "content-type": "multipart/form-data",
                                Authorization: `Bearer ${authToken}`,
                              },
                            };
                            const res = await axios.put(
                              `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-syllabus/${id}`,
                              payload,
                              config
                            );
                            //console.log(res.msg)
                            setLoading(false);
                            toast.success("Updated");
                            setTimeout(() => window.location.reload(), 800);
                          };

                          return (
                            <TableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, title)} />
                                                    </TableCell> */}
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedRows.includes(row.id)}
                                  onChange={() => handleRowSelect(row.id)}
                                />
                              </TableCell>
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {index + 1}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {isEdit && selectedId === id ? (
                                      <Input
                                        onChange={(e) => onChange(e, row, id)}
                                        // value={value}
                                        name="title"
                                        placeholder={title}
                                      />
                                    ) : (
                                      <span>{title}</span>
                                    )}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              {/* <TableCell align="left">
                                                        {isEdit && selectedId === id ? (<Input
                                                            onChange={(e) => onChange(e, row)}
                                                            // value={value}
                                                            name='category'
                                                            placeholder={category.title}



                                                        />) : <span>{category.title}</span>}
                                                    </TableCell> */}

                              <TableCell align="center">
                                {/* {isEdit && selectedId === id ? (
                                  <Input
                                    // onChange={(e) => onChange(e, row)}
                                    // value={value}
                                    name="subject"
                                    placeholder={exam_name || classes_name}
                                  />
                                ) : ( */}
                                <span>{course || "NA"}</span>
                                {/* )} */}
                              </TableCell>
                              <TableCell align="center">
                                {/* {isEdit && selectedId === id ? (
                                  <Input
                                    // onChange={(e) => onChange(e, row)}
                                    // value={value}
                                    name="subject"
                                    placeholder={exam_name || classes_name}
                                  />
                                ) : ( */}
                                <span>{exam_name || classes_name || "NA"}</span>
                                {/* )} */}
                              </TableCell>

                              <TableCell align="left">
                                {isEdit && selectedId === id ? (
                                  <>
                                    <input
                                      style={{
                                        display:
                                          resource_type === "link"
                                            ? "block"
                                            : "none",
                                      }}
                                      id="choose-file"
                                      type={
                                        resource_type === "link"
                                          ? "url"
                                          : "file"
                                      }
                                      onChange={changeHandler}
                                    />
                                    <label htmlFor="choose-file">
                                      {resource_type === "link" ? (
                                        ""
                                      ) : (
                                        <FileUploadIcon
                                          sx={{
                                            cursor: "pointer",
                                            "&:hover": {
                                              color: "red",
                                            },
                                          }}
                                        />
                                      )}
                                    </label>
                                  </>
                                ) : (
                                  <a
                                    href={file_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "black" }}
                                  >
                                    {resource_type === "link" ? (
                                      <LinkIcon />
                                    ) : (
                                      <PictureAsPdfIcon
                                        sx={{
                                          cursor: "pointer",
                                          "&:hover": {
                                            color: "red",
                                          },
                                        }}
                                      />
                                    )}
                                  </a>
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {isEdit && selectedId === id ? (
                                  <TextField
                                    onChange={(e) => onChange(e, row)}
                                    size="small"
                                    label={
                                      language === "hi"
                                        ? "Hindi"
                                        : language == "en"
                                        ? "English"
                                        : "Hindi/English"
                                    }
                                    name="language"
                                    placeholder={
                                      language === "hi"
                                        ? "Hindi"
                                        : language == "en"
                                        ? "English"
                                        : "Hindi/English"
                                    }
                                    select
                                    sx={{ width: "100px" }}
                                  >
                                    {languages?.map((language, index) => (
                                      <MenuItem
                                        key={index}
                                        value={language.medium_code}
                                      >
                                        {language.medium_name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                ) : (
                                  <span>
                                    {" "}
                                    {language === "hi"
                                      ? "Hindi"
                                      : language == "en"
                                      ? "English"
                                      : "Hindi/English"}
                                  </span>
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {isEdit && selectedId === id ? (
                                  <TextField
                                    onChange={(e) => onChange(e, row)}
                                    size="small"
                                    label={
                                      is_active == "true" ? "Publish" : "Draft"
                                    }
                                    name="is_active"
                                    placeholder={
                                      is_active == "true" ? "Publish" : "Draft"
                                    }
                                    select
                                    sx={{ width: "100px" }}
                                  >
                                    <MenuItem value={"true"}>Publish</MenuItem>
                                    <MenuItem value={"false"}>Draft</MenuItem>
                                  </TextField>
                                ) : (
                                  <span
                                    style={{
                                      background:
                                        is_active == "true" ? "green" : "red",
                                      padding: "5px",
                                      borderRadius: "10px",
                                      color: "white",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {is_active == "true" ? "Publish" : "Draft"}
                                  </span>
                                )}
                              </TableCell>
                              {isEdit && selectedId === id ? (
                                <TableCell>
                                  <Button
                                    variant="outlined"
                                    onClick={handleUpdate}
                                  >
                                    Update
                                  </Button>
                                </TableCell>
                              ) : null}

                              <TableCell onClick={() => setSelectedId(id)}>
                                <UserMoreMenu
                                  id={id}
                                  req="delete-syllabus"
                                  isEdit={isEdit}
                                  setIsEdit={setIsEdit}
                                  setLoading={setLoading}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
            {/* <Box mt={2} width={"100%"}>
              
              <Button
                variant="contained"
                color="error"
                width={"100%"}
                onClick={handleDeleteSelected}
                disabled={handleDeleteButtonDisabled()}
              >
                Delete Selected Rows
              </Button>
            </Box> */}
          </Container>
        </Page>
      )}
    </>
  );
}
