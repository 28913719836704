import { Avatar, Button, Card, Stack, TextField, Box } from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import io from "socket.io-client";
import socket from "./sockets";
import { RHFTextField } from "../../../components/hook-form";
import { useParams } from "react-router-dom";
const CryptoJS = require("crypto-js");

function extractYouTubeVideoId(url) {
  // Regular expressions to match various YouTube URL formats
  const patterns = [
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?.*&v=([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtu.be\/([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/live\/([a-zA-Z0-9_\-?]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?list=[a-zA-Z0-9_-]+&v=([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube-nocookie\.com\/embed\/([a-zA-Z0-9_-]+)/,
  ];

  // Try each pattern and return the video ID if a match is found
  for (const pattern of patterns) {
    const match = url.match(pattern);
    if (match && match[1]) {
      return match[1];
    }
  }

  // If no match is found, return null or handle the case as needed
  return null;
}

const YoutubeLive = () => {
  const { lectId, lectTitle, lectDesc, ytUrl } = useParams();
  let newLink = "";
  const decodedYtUrl = decodeURIComponent(ytUrl);

  Array.from(decodedYtUrl).forEach((elem) => {
    if (elem !== "@") newLink += elem;
    else newLink += "/";

    // console.log('newLin', newLink)
  });
  // console.log(newLink);
  var videoId = extractYouTubeVideoId(newLink);
  const [newUrl, setnewUrl] = useState(
    `https://www.youtube.com/embed/${videoId}`
  );
  const detailObject = JSON.parse(localStorage.getItem("detailObject"));
  const name = detailObject?.username;
  const username = detailObject?.username;
  // console.log(detailObject?.data);
  const id = detailObject?.adminId;
  const profileIcon =
    detailObject?.profilePhoto ??
    "https://storage-upschindi.s3.ap-south-1.amazonaws.com/data/images/avatar.png";
  // let socket = io.connect('https://backend-prod.invictaa.com/api/v1', { transports: ['websocket'] })

  const [msg, setMsg] = useState("");
  const [messagelist, setMessageList] = useState([]);
  const [nameOfUser, setNameOfUser] = useState();
  const [IconIfUser, setIconIfUser] = useState("");
  const [isLiveFullScreen, setIsLiveFullScreen] = useState(false);
  // const containerRef = useRef(null);

  const joinRoom = () => {
    const roomId = lectId;

    socket.emit("create", roomId);
  };

  useEffect(() => {
    joinRoom();
  }, []);
  const sendMessage = async (msgg) => {
    // const msgg
    const roomId = lectId;
    // console.log('line26',details)
    await socket.emit("addUser", { username, id });
    // console.log(msgg  + " "+ name + " "+roomId + " "+ profileIcon);
    await socket.emit("send-message", msgg, name, roomId, profileIcon);
    setMsg("");
  };

  const arrivalMessage = (message, name, userIconUrl) => {
    setMessageList((prev) => [
      ...prev,
      { message: message, name: name, icon: userIconUrl },
    ]);
  };

  useEffect(() => {
    socket.on("receive-message", (message, name, userIconUrl) => {
      // console.log('line32', message)
      arrivalMessage(message, name, userIconUrl);
      setNameOfUser(name);
      setIconIfUser(userIconUrl);
      // console.log(message)
    });
  }, []);

  const [users, usersSet] = useState();
  const [lectDetails, setlectureDetails] = useState();
  const [description, setDescription] = useState();
  //Lecture Resource
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    // console.log(lectId);
    async function fetchDetails() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-lecture/${lectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      console.log(responseJson?.data);
      if (responseJson?.data) {
        const videoId = extractYouTubeVideoId(
          responseJson?.data?.lecture_type == "1"
            ? responseJson?.data?.link
            : responseJson?.data?.material_url
        );
        console.log(videoId);
        if (videoId) {
          setnewUrl(`https://www.youtube.com/embed/${videoId}`);
        }
      }
      setDescription(responseJson?.data?.description);
      // debugger;
      // console.log(responseJson?.data);
      usersSet(responseJson?.data);
      // setLoading(false)
      // setLoadingTable(false)
      //Lecture Details
      // const fullResponseForLecture = await fetch(
      //   `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLecturedetails/${lectId}`,
      //   {
      //     method: "GET",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      // const responseJsonForLecture = await fullResponseForLecture.json();
      // console.log(responseJsonForLecture.data);
      // setlectureDetails(responseJsonForLecture.data);
    }

    fetchDetails();
  }, []);

  const handleChange = (e) => {
    setMsg(e.target?.value);
    // console.log(e.target?.value)
    // if (e.target?.value !== '') { setMsg(e.target?.value) }
  };

  // console.log('msglist', users)
  // console.log('Lecture details', lectDetails)
  // console.log('msglist', messagelist)
  return (
    <div
      className="parent"
      style={{
        height: "100%",
      }}
    >
      <Stack direction={isLiveFullScreen ? "column" : "row"} gap="20px">
        <Stack
          direction="column"
          sx={{ width: "100%", height: "80vh !important" }}
        >
          <div
            style={{
              height: isLiveFullScreen ? "70%" : "60%",
              width: "100%",
              padding: "20px",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                background: "black",
                borderRadius: "10px",
                marginBottom: "10px",
                overflow: "hidden",
              }}
            >
              <iframe
                // width={isLiveFullScreen ? "100%" : "780"}
                // height={isLiveFullScreen ? "340" : "255"}
                width={isLiveFullScreen ? "100%" : "100%"}
                height={isLiveFullScreen ? "440" : "360"}
                // src='https://www.youtube.com/embed/DAEnJzUNZYE?si=59XwYssuy9g29qEN'>
                src={newUrl}
                allowFullScreen
              >
                {/* // src={newUrl}> */}
              </iframe>
            </div>
          </div>
          <h5 style={{ height: "5%" }}>{lectDetails?.lecture_title}</h5>
          <div style={{ height: "50%", width: "100%" }}>
            <Card
              sx={{
                height: "auto",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                // style={{ overflowY: "scroll" }}
                dangerouslySetInnerHTML={{ __html: description }}
              ></p>
              {/* {console.log(users)} */}
              {/* {users?.length !== 0
                ? users?.map((item, index) => {
                    return (
                      <div key={index}>
                        {item?.resourceType == "pdf" ? (
                          <Stack direction="row" alignItems="center">
                            {item?.title}:
                            <a href={item?.upload_file?.fileLoc}>
                              <PictureAsPdfIcon />
                            </a>
                          </Stack>
                        ) : (
                          <p>
                            {item?.title}: {item?.upload_file?.fileLoc}
                          </p>
                        )}
                      </div>
                    );
                  })
                : "Nothing"} */}
            </Card>
          </div>
        </Stack>

        {/* <Stack
          direction="column"
          sx={{ width: isLiveFullScreen ? "100%" : "70%" }}
        >
          <Card
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflowY: "hidden",
            }}
          >
            <div
              style={{
                height: "60px",
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "10px ",
                paddingRight: "10px",
                alignItems: "center",
                background: "rgba(255, 137, 158, 0.12)",
              }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                fill="#9603F2"
                <path
                  d="M7.5 24C7.075 24 6.71875 23.8563 6.43125 23.5688C6.14375 23.2812 6 22.925 6 22.5V19.5H25.5V6H28.5C28.925 6 29.2812 6.14375 29.5687 6.43125C29.8562 6.71875 30 7.075 30 7.5V30L24 24H7.5ZM0 22.5V1.5C0 1.075 0.14375 0.71875 0.43125 0.43125C0.71875 0.14375 1.075 0 1.5 0H21C21.425 0 21.7812 0.14375 22.0688 0.43125C22.3563 0.71875 22.5 1.075 22.5 1.5V15C22.5 15.425 22.3563 15.7813 22.0688 16.0688C21.7812 16.3563 21.425 16.5 21 16.5H6L0 22.5ZM19.5 13.5V3H3V13.5H19.5Z"
                  fill="#e6e6ff"
                  fill-opacity="0.75"
                />
              </svg>
              &nbsp; Live Comments
              <Button onClick={() => setIsLiveFullScreen(!isLiveFullScreen)}>
                {!isLiveFullScreen ? <ZoomOutMapIcon /> : <ZoomInMapIcon />}
              </Button>
            </div>

            <div
              style={{
                overflowY: "scroll",
                padding: "20px",
                marginBottom: "70px",
                display: "flex",
                flexDirection: "column-reverse",
                height: isLiveFullScreen ? "50vh" : "60vh",
              }}
            >
              {messagelist?.map((item) => {
                // console.log(item)
                // console.log
                return (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={name == item?.name ? "" : ""}
                    mb={2}
                    sx={{
                      width: "100%",
                      color: name == item?.name ? "green" : "",
                    }}
                  >
                    <Avatar
                      src={IconIfUser}
                      style={{ height: "30px", width: "30px" }}
                    />{" "}
                    &nbsp;
                    <h5 style={{ textAlign: "start" }}>{item?.name} : </h5>
                    <br /> &nbsp;
                    <span style={{ fontSize: "12px" }}>{item?.message}</span>
                  </Stack>
                );
                     return (<Stack
                     direction='row'
                     alignItems="center"
                     justifyContent={name === item?.name ? 'flex-end' : ''}
                     mb={2}
                     sx={{ width: '100%' }}
                   >
                     <Avatar src={IconIfUser} style={{ height: '30px', width: '30px' }} /> &nbsp;
                     <h5 style={{ textAlign: 'start' }}>{item?.name} :</h5>&nbsp;
                     <Box style={{ overflowY: 'auto', maxHeight: '100px', width: '100%',boxSizing: 'border-box', }}>
                       <span style={{ fontSize: '12px' }}>{item?.message}</span>
                     {/* </Box>
                   </Stack> *
              })}
            </div>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                position: "absolute",
                width: "100%",
                bottom: "0",
                padding: "10px",
              }}
            >
              {" "}
              <TextField
                type="text"
                value={msg}
                sx={{ width: "100%" }}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <Button
                // ref={inputRef}
                variant="outlined"
                disabled={msg.trim() === ""}
                onClick={() => sendMessage(msg)}
              // placeholder="Enter your message"
              >
                Send
              </Button>
              <Button
                                // ref={inputRef}
                                variant='outlined'
                                onClick={() => joinRoom()}
                            // placeholder="Enter your message"
                            >
                                Join
                            </Button>
            </Stack>

            <h4>AAyein</h4>
          </Card>
        </Stack> */}
      </Stack>
    </div>
  );
};

export default YoutubeLive;
