import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  TextField,
  MenuItem,
  TableHead,
  Box,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import axios from "axios";
import LinkIcon from "@mui/icons-material/Link";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "S.No", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  // { id: 'category', label: 'Category', alignRight: false },
  { id: "video_url", label: "Video", alignRight: false },
  { id: "course", label: "Category", alignRight: false },

  { id: "exam name", label: "Exam Name / Class", alignRight: false },
  { id: "language", label: "Type", alignRight: false },
  { id: "created_at", label: "Date", alignRight: false },
  { id: "is_active", label: "Status", alignRight: false },
  { id: "is_active", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function YoutubeTable() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");
  const [selectedId, setSelectedId] = useState();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPageRows = users
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map((user) => user.id);
      setSelectedRows(currentPageRows);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };
  const handleRowSelect = (userId) => {
    if (selectedRows.includes(userId)) {
      setSelectedRows(selectedRows.filter((id) => id !== userId));
    } else {
      setSelectedRows([...selectedRows, userId]);
    }
  };
  const handleDeleteButtonDisabled = () => {
    return selectedRows.length === 0;
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-youtube`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson.data);
      setLoading(false);
      // if (responseJson.msg === 'not an admin') {
      //     localStorage.removeItem("isLoggedIn")
      //     localStorage.removeItem("details")
      //     navigate('/')
      // };
    }

    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users?.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;
  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  //console.log(updatedValues)
  const handleDeleteSelected = async () => {
    const token = localStorage.getItem("token");
    console.log([...selectedRows]);
    // const res = await axios.delete(
    //   `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-youtube`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   },
    //   { ids: [...selectedRows] }
    // );
    // console.log(res);
    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-youtube`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ids: [...selectedRows] }),
      }
    );
    const fullres = res.json();
    toast.success("Delete successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1500);

    console.log(res);
  };

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Demo Videos">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
                {selectedRows.length > 0 && (
                  <Box height={"40px"}>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        height: "40px",
                      }}
                      onClick={handleDeleteSelected}
                      // disabled={handleDeleteButtonDisabled()}
                    >
                      Delete Selected Rows
                    </Button>
                  </Box>
                )}

                {/*  <ExportCSV csvData={filteredUsers} fileName="Youtube Data" /> */}
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    {/* <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    /> */}
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                            indeterminate={
                              selectedRows.length > 0 &&
                              selectedRows.length < users.length
                            }
                          />
                        </TableCell>
                        {TABLE_HEAD.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.alignRight ? "right" : "left"}
                          >
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => {
                          const {
                            id,
                            title,
                            category,
                            course,
                            exam_name,
                            classes_name,

                            is_live,
                            created_at,
                            is_active,
                            video_url,
                          } = row;
                          const isItemSelected = selected.indexOf(title) !== -1;
                          {
                            /* //console.log('activ statues' + is_active) */
                          }
                          const onChange = (e, row, id) => {
                            const { name, value } = e.target;

                            setUpdatedValues((prev) => {
                              return { ...prev, [name]: value };
                            });
                          };
                          const handleUpdate = async () => {
                            setLoading(true);
                            const token = localStorage.getItem("token");
                            const authToken = token;

                            const config = {
                              headers: {
                                "content-type": "application/json",
                                Authorization: `Bearer ${authToken}`,
                              },
                            };
                            const res = await axios.put(
                              `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-youtube/${id}`,
                              updatedValues,
                              config
                            );
                            setLoading(false);
                            toast.success("Updated");
                            setTimeout(() => window.location.reload(), 800);
                          };
                          return (
                            <TableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedRows.includes(row.id)}
                                  onChange={() => handleRowSelect(row.id)}
                                />
                              </TableCell>
                              {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, title)} />
                                                    </TableCell> */}
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {index + 1 + rowsPerPage * page}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {isEdit && selectedId === id ? (
                                      <Input
                                        onChange={(e) => onChange(e, row, id)}
                                        // value={value}
                                        name="title"
                                        placeholder={title}
                                      />
                                    ) : (
                                      <span>{title}</span>
                                    )}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                <a href={video_url} style={{ color: "black" }}>
                                  <LinkIcon
                                    sx={{
                                      cursor: "pointer",
                                      "&:hover": {
                                        color: "red",
                                      },
                                    }}
                                  />
                                </a>
                              </TableCell>

                              <TableCell align="center">
                                <span>{course}</span>
                              </TableCell>

                              <TableCell align="center">
                                <span>{exam_name || classes_name || "NA"}</span>
                              </TableCell>

                              <TableCell align="left">
                                {" "}
                                {isEdit && selectedId === id ? (
                                  <TextField
                                    onChange={(e) => onChange(e, row)}
                                    // value={value}
                                    label={is_live === "true" ? "Live" : "Demo"}
                                    name="is_live"
                                    placeholder={
                                      is_live === "true" ? "Live" : "Demo"
                                    }
                                    select
                                    sx={{ width: "100px" }}
                                  >
                                    <MenuItem value="true">Live</MenuItem>
                                    <MenuItem value="false">Demo</MenuItem>
                                  </TextField>
                                ) : (
                                  <span>
                                    {" "}
                                    {is_live === "true" ? "Live" : "Demo"}
                                  </span>
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {created_at?.split("T")[0]}
                              </TableCell>
                              <TableCell align="left">
                                {isEdit && selectedId === id ? (
                                  <TextField
                                    onChange={(e) => onChange(e, row)}
                                    // value={value}
                                    label={
                                      is_active == "true"
                                        ? "Published"
                                        : "Draft"
                                    }
                                    name="is_active"
                                    placeholder={
                                      is_active == "true"
                                        ? "Published"
                                        : "Draft"
                                    }
                                    select
                                    sx={{ width: "100px" }}
                                  >
                                    <MenuItem value={"true"}>Publish</MenuItem>
                                    <MenuItem value={"false"}>Draft</MenuItem>
                                  </TextField>
                                ) : (
                                  <span
                                    style={{
                                      padding: "5px",
                                      borderRadius: "10px",
                                      color:
                                        is_active == "true" ? "green" : "red",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {is_active == "true"
                                      ? "Published"
                                      : "Draft"}
                                  </span>
                                )}
                              </TableCell>
                              {isEdit && selectedId === id ? (
                                <TableCell align="left">
                                  <Button
                                    variant="outlined"
                                    onClick={handleUpdate}
                                  >
                                    Update
                                  </Button>
                                </TableCell>
                              ) : null}

                              <TableCell onClick={() => setSelectedId(id)}>
                                <UserMoreMenu
                                  id={id}
                                  req="delete-youtube"
                                  isEdit={isEdit}
                                  setIsEdit={setIsEdit}
                                  setLoading={setLoading}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
            {/* <Box mt={2} width={"100%"}>
              
              <Button
                variant="contained"
                color="error"
                width={"100%"}
                onClick={handleDeleteSelected}
                disabled={handleDeleteButtonDisabled()}
              >
                Delete Selected Rows
              </Button>
            </Box> */}
          </Container>
        </Page>
      )}
    </>
  );
}
