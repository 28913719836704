import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { getFileUrl } from '../../../utils/firebaseUtils';

// ----------------------------------------------------------------------

export default function AddSubject() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [image_url, setImageUrl] = useState("");
    const [focusThree, setFocusedThree] = useState(false)
    const [imageLoading, setImageLoading] = useState(false)
    const [pdf, setPdf] = useState(false);

    const RegisterSchema = Yup.object().shape({
        name: Yup.string().required('Title required'),
        is_active: Yup.string().required('Status required'),
        // lastName: Yup.string().required('Last name required'),
        // category: Yup.string().required('Category is required'),
        // data: Yup.string().required('Data is required'),
        // is_active: Yup.string().required('Status Required'),
    });

    const defaultValues = {
        name: '',
        is_active: "true",
        // category: '',
        // data: '',
        // is_active: null,
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        setLoading(true);
        console.log(data);
        let token = localStorage.getItem("token");
        // //console.log(token);
        // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlRlc3RpbmdAZ21haWwuY29tIiwiaWF0IjoxNjYzOTQzMTc5LCJleHAiOjE2NjM5NDQ5Nzl9.ix9ZXfVEqHrH20-bnMxHQD9c4Z0Z3plfkJWu3YiisKY'
        try {

            const res = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/classroom-subjects`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({...data, image_url})
            })
            const result = await res.json()
            
            if (result.response_code === 200) {
                toast.success(result.message)
                setLoading(false)
                reset()
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
                // navigate('/dashboard', { replaced: true })
            }
            else{
              setLoading(false);
              toast.error(result.message);
            }
        } catch (error) {
            //console.log(error);
        }

    };

    const changeHandler = async(event)=>{
      setImageLoading(true);
      const url = await getFileUrl(event.target?.files[0]);
      setImageUrl(url);
      setImageLoading(false);
    }

    return (
      <>
        <Stack spacing={3} px={5}>
          <Typography variant="h3">Add Subject</Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "70%" }}>
              <RHFTextField name="name" label="Title" />
              <RHFTextField
                  name="image"
                  onChange={changeHandler}
                  id="file"
                  label="Subject Image (Optional)"
                  helperText="Image Only"
                  onFocus={() => setFocusedThree(true)}
                  onBlur={() => setFocusedThree(false)}
                  // inputProps={{ multiple: true }}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setPdf(true)}
                  inputProps={{ accept: "image/*" }}
                  type={ pdf || focusThree ? "file" : "text"}
                />
              <RHFTextField
                name="is_active"
                label="Status"
                type="checkbox"
                select
              >
                <MenuItem value={"true"}>Publish</MenuItem>
                <MenuItem value={"false"}>Draft</MenuItem>
              </RHFTextField>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
                sx={{
                  bgcolor: "#e6e6ff",
                  color: "black",
                  marginTop: "5%",
                  "&:hover": { background: " #8080ff" },
                }}
              >
                Add
              </LoadingButton>
            </Stack>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Stack>
      </>
    );
}
