import Iconify from "../../components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const role = localStorage.getItem("role");
const detailObject = localStorage.getItem("detailObject");
let d = JSON.parse(detailObject);
//console.log(d)
//Main Heading
let studentsheading = {};
if (
  d?.access?.includes("All Users") ||
  d?.access?.includes("Course Purchases") ||
  d?.access?.includes("Test Series Purchases") ||
  d?.access?.includes("Purchase Report") ||
  d?.access?.includes("User Report")
) {
  //console.log(' goneInside')
  studentsheading = {
    title: "Users",
    path: "#",
    icon: getIcon("eva:people-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let batchesheading = {};
if (
  d?.accessToContent?.includes("All Batches") ||
  d?.accessToContent?.includes("Add Batch") ||
  d?.accessToContent?.includes("Add Center Lecture") ||
  d?.accessToContent?.includes("Add Center Resource")
) {
  batchesheading = {
    title: "Courses",
    path: "#",
    icon: getIcon("eva:shopping-bag-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let testseriesheading = {};
if (
  d?.accessToContent?.includes("All Test Series") ||
  d?.accessToContent?.includes("Add Test Series")
) {
  //console.log('came in here test ')
  testseriesheading = {
    title: "Test Series",
    path: "#",
    icon: getIcon("eva:file-text-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let quizheading = {};
if (
  d?.accessToContent?.includes("All Quiz") ||
  d?.accessToContent?.includes("Add Quiz")
) {
  //console.log('came in here test ')
  quizheading = {
    title: "Quiz",
    path: "#",
    icon: getIcon("material-symbols:quiz-outline"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let couponheading = {};
if (
  d?.accessToContent?.includes("All Coupon") ||
  d?.accessToContent?.includes("Add Coupon")
) {
  //console.log('came in here test ')
  couponheading = {
    title: "Coupon",
    path: "#",
    icon: getIcon("ri:coupon-3-line"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}

let resourcesheading = {};
if (
  d?.accessToContent?.includes("Recorded Video") ||
  d?.accessToContent?.includes("Pathyakram") ||
  d?.accessToContent?.includes("Notes") ||
  d?.accessToContent?.includes("AIR") ||
  d?.accessToContent?.includes("Previous Year Question Papers") ||
  d?.accessToContent?.includes("News Clip") ||
  d?.accessToContent?.includes("Teacher Generated Content Of Batch") ||
  d?.accessToContent?.includes("Alerts")
) {
  resourcesheading = {
    title: "Resources",
    path: "#",
    icon: getIcon("eva:alert-triangle-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let generalsettings = {};
if (
  d?.accessToContent?.includes("Bannner") ||
  d?.accessToContent?.includes("Logout Users") ||
  d?.accessToContent?.includes("Subject") ||
  d?.accessToContent?.includes("Youtube") ||
  d?.accessToContent?.includes("Telegram") ||
  d?.accessToContent?.includes("Category") ||
  d?.accessToContent?.includes("Sub Category") ||
  d?.accessToContent?.includes("Contact Us")
) {
  generalsettings = {
    title: "General Settings",
    path: "#",
    icon: getIcon("ci:settings"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let managestaff = {};
if (
  d?.accessToContent?.includes("All Staff") ||
  d?.accessToContent?.includes("Add Staff") ||
  d?.accessToContent?.includes("Sub Admin")
) {
  managestaff = {
    title: "Manage Staff",
    path: "/dashboard/staff",
    icon: getIcon("ic:outline-manage-accounts"),
    dropIcon: getIcon("mi:caret-down"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let assignment = {};
if (
  d?.accessToContent?.includes("All Assignment") ||
  d?.accessToContent?.includes("Add Assignment")
) {
  assignment = {
    title: "Assignment",
    path: "/dashboard/assignment",
    icon: getIcon("eva:book-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let testimonial = {};
if (
  d?.accessToContent?.includes("All Testimonial") ||
  d?.accessToContent?.includes("Add Testimonial")
) {
  testimonial = {
    title: "Testimonial",
    path: "/dashboard/testimonial",
    icon: getIcon("dashicons:testimonial"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}

//Sub Heading
let students = {};
if (d?.accessToContent?.includes("All Users")) {
  students = {
    title: "All Students",
    path: "/dashboard/students",
    navicon: "ph:student",
    cName: "sub-nav",
  };
}
let studentsbatches = {};
if (d?.accessToContent?.includes("Course Purchases")) {
  studentsbatches = {
    title: "Courses Purchases",
    path: "/dashboard/studentBatches",
    navicon: "ic:baseline-support-agent",
    cName: "sub-nav",
  };
}

let testseriesPurchase = {};
if (d?.accessToContent?.includes("Test Series Purchases")) {
  testseriesPurchase = {
    title: "Test Series Purchases",
    path: "/dashboard/studentTestSeries",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}

let purchaseReport = {};
if (d?.accessToContent?.includes("Purchase Report")) {
  purchaseReport = {
    title: "Purchase Report",
    path: "/dashboard/purchaseReport",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}

let userReport = {};
if (d?.accessToContent?.includes("User Report")) {
  userReport = {
    title: "User Report",
    path: "/dashboard/userReport",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}

let allbatches = {};
if (d?.accessToContent?.includes("All Batches")) {
  allbatches = {
    title: "All Courses",
    path: "/dashboard/batches",
    navicon: "material-symbols:batch-prediction",
    cName: "sub-nav",
  };
}
let addbatches = {};
if (d?.accessToContent?.includes("Add Batch")) {
  addbatches = {
    title: "Add Courses",
    path: "/dashboard/addBatch",
    navicon: "bxs:message-square-add",
    cName: "sub-nav",
  };
}
let addCenterLectures = {};
if (d?.accessToContent?.includes("Add Center Lecture")) {
  // console.log(accessToContentTab);
  addCenterLectures = {
    title: "Add Lecture",
    path: "/dashboard/addCenterLecture",
    navicon: "bxs:message-square-add",
    cName: "sub-nav",
  };
  // console.log({...addCenterLecture});
}
let addCenterResource = {};
if (d?.accessToContent?.includes("Add Center Lecture")) {
  // console.log(accessToContentTab);
  addCenterResource = {
    title: "Add Resource",
    path: "/dashboard/addCenterResource",
    navicon: "bxs:message-square-add",
    cName: "sub-nav",
  };
  // console.log({...addCenterLecture});
}
let testseries = {};
if (d?.accessToContent?.includes("All Test Series")) {
  testseries = {
    title: "All Test Series",
    path: "/dashboard/test",
    navicon: "akar-icons:book-close",
    cName: "sub-nav",
  };
}
let addtestseries = {};
if (d?.accessToContent?.includes("Add Test Series")) {
  addtestseries = {
    title: "Add Test Series",
    path: "/dashboard/addTest",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}
let quiz = {};
if (d?.accessToContent?.includes("All Quiz")) {
  quiz = {
    title: "All Quiz",
    path: "/dashboard/quiz",
    navicon: "akar-icons:book-close",
    cName: "sub-nav",
  };
}
let addquiz = {};
if (d?.accessToContent?.includes("Add Quiz")) {
  addquiz = {
    title: "Add Quiz",
    path: "/dashboard/addQuiz",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}

let allcoupon = {};
if (d?.accessToContent?.includes("All Coupon")) {
  allcoupon = {
    title: "All Coupon",
    path: "/dashboard/allCoupon",
    navicon: "akar-icons:book-close",
    cName: "sub-nav",
  };
}
let addcoupon = {};
if (d?.accessToContent?.includes("Add Coupon")) {
  addcoupon = {
    title: "Add Coupon",
    path: "/dashboard/addCoupon",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}
let recordedvideos = {};
if (d?.accessToContent?.includes("Recorded Video")) {
  recordedvideos = {
    title: "Recorded Video",
    path: "/dashboard/recorded",
    navicon: "carbon:course",
    cName: "sub-nav",
  };
}
let Pathyakram = {};
if (d?.accessToContent?.includes("Pathyakram")) {
  Pathyakram = {
    title: "Pathyakram",
    path: "/dashboard/Pathyakram",
    navicon: "carbon:course",
    cName: "sub-nav",
  };
}
let notes = {};
if (d?.accessToContent?.includes("Notes")) {
  notes = {
    title: "Notes",
    path: "/dashboard/notes",
    navicon: "charm:notes",
    cName: "sub-nav",
  };
}
let pyq = {};
if (d?.accessToContent?.includes("Previous Year Question Papers")) {
  pyq = {
    title: "Previous Year Question Papers",
    path: "/dashboard/previousYearQuestionPapers",
    navicon: "carbon:course",
    cName: "sub-nav",
  };
}
let air = {};
if (d?.accessToContent?.includes("AIR")) {
  air = {
    title: "AIR",
    path: "/dashboard/air",
    navicon: "carbon:radio",
  };
}
let newsclip = {};
if (d?.accessToContent?.includes("News Clip")) {
  newsclip = {
    title: "News Clip",
    path: "/dashboard/newsClip",
    navicon: "wpf:news",
  };
}
let teachercontent = {};
if (d?.accessToContent?.includes("Teacher Generated Content Of Batch")) {
  teachercontent = {
    title: "Teacher Generated Content of Batch",
    path: "/dashboard/teacherGenerated",
    navicon: "bx:book-content",
  };
}
let alerts = {};
if (d?.accessToContent?.includes("Alerts")) {
  alerts = {
    title: "Alerts",
    path: "/dashboard/customNotification",
    navicon: "carbon:course",
    cName: "sub-nav",
  };
}
let teacher = {};
if (d?.accessToContent?.includes("Teachers")) {
  teacher = {
    title: "Teachers",
    path: "/dashboard/teacher",
    navicon: "fa-solid:chalkboard-teacher",
    cName: "sub-nav",
  };
}
let mod = {};
if (d?.accessToContent?.includes("Moderators")) {
  mod = {
    title: "Moderators",
    path: "/dashboard/moderators",
    navicon: "ic:outline-interpreter-mode",
    cName: "sub-nav",
  };
}
let subadmin = {};
if (d?.accessToContent?.includes("Sub Admin")) {
  subadmin = {
    title: "Sub Admin",
    path: "/dashboard/subAdmin",
    navicon: "subway:admin-1",
  };
}
let banner = {};
if (d?.accessToContent?.includes("Banner")) {
  banner = {
    title: "Banner",
    path: "/dashboard/banner",
    navicon: "ri:file-settings-line",
    cName: "sub-nav",
  };
}
let logoutusers = {};
if (d?.accessToContent?.includes("Logout Users")) {
  logoutusers = {
    title: "Logout Users",
    path: "/dashboard/logoutUsers",
    navicon: "ant-design:logout-outlined",
    cName: "sub-nav",
  };
}
let subject = {};
if (d?.accessToContent?.includes("Subject")) {
  subject = {
    title: "Subject",
    path: "/dashboard/subject",
    navicon: "ri:file-settings-line",
    cName: "sub-nav",
  };
}
let youtube = {};
if (d?.accessToContent?.includes("Youtube")) {
  youtube = {
    title: "Youtube",
    path: "/dashboard/youtube",
    navicon: "ri:file-settings-line",
    cName: "sub-nav",
  };
}
let telegram = {};
if (d?.accessToContent?.includes("Telegram")) {
  telegram = {
    title: "Telegram",
    path: "/dashboard/telegram",
    navicon: "ri:file-settings-line",
    cName: "sub-nav",
  };
}
let category = {};
if (d?.accessToContent?.includes("Category")) {
  category = {
    title: "Category",
    path: "/dashboard/category",
    navicon: "ic:outline-category",
    cName: "sub-nav",
  };
}
let subcategory = {};
if (d?.accessToContent?.includes("Sub Category")) {
  subcategory = {
    title: "Sub Category",
    path: "/dashboard/subCategory",
    navicon: "ic:outline-category",
    cName: "sub-nav",
  };
}
let contactus = {};
if (d?.accessToContent?.includes("Contact Us")) {
  contactus = {
    title: "Contact Us",
    path: "/dashboard/contactUs",
    navicon: "cil:contact",
    cName: "sub-nav",
  };
}
let helpandsupport = {};
if (d?.accessToContent?.includes("Help And Support")) {
  helpandsupport = {
    title: "Help and Support",
    path: "/dashboard/helpAndSupport",
    navicon: "ic:baseline-support-agent",
    cName: "sub-nav",
  };
}

export const navConfigForTeacher = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon("eva:pie-chart-2-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  },
  {
    ...studentsheading,

    subNav: [
      {
        ...students,
      },
      {
        ...studentsbatches,
      },
      { ...testseriesPurchase },
      { ...purchaseReport },
      { ...userReport },
    ],
  },
  {
    batchesheading,
    // title: 'Batches',
    // path: '#',
    // icon: getIcon('eva:shopping-bag-fill'),
    // dropIcon: getIcon('gridicons:dropdown'),
    // upIcon: getIcon('mi:caret-up'),

    subNav: [
      {
        allbatches,
        // title: "Batches",
        // path: "/dashboard/batches",
        // navicon: 'material-symbols:batch-prediction',
        // cName: "sub-nav",
      },
      {
        ...addbatches,
      },
      {
        ...addCenterLectures,
      },
      {
        ...addCenterResource,
      },
    ],
  },
  {
    ...testseriesheading,
    subNav: [
      {
        ...testseries,
      },
      {
        ...addtestseries,
      },
    ],
  },
  {
    ...quizheading,

    subNav: [
      {
        ...quiz,
      },
      {
        ...addquiz,
      },
    ],
  },
  {
    ...couponheading,
    subNav: [{ ...allcoupon }, { ...addcoupon }],
  },

  {
    ...resourcesheading,
    subNav: [
      {
        ...recordedvideos,
      },
      {
        ...Pathyakram,
      },
      // {
      //     title: "All Resources",
      //     path: "/dashboard/resources",
      //     navicon: 'carbon:course',
      //     cName: "sub-nav",
      // },
      // {
      //     title: "Add Resources",
      //     path: "/dashboard/addResources  ",
      //     navicon: 'carbon:course',
      //     cName: "sub-nav",
      // },
      {
        ...notes,
      },
      { ...pyq },
      {
        ...air,
      },
      {
        ...newsclip,
      },
      {
        ...teachercontent,
      },
      { ...alerts },
    ],
  },
  {
    ...assignment,
  },
  {
    ...managestaff,
    // subNav: [
    //     {
    //         ...teacher
    //     },
    //     {
    //         ...mod
    //     },
    //     {
    //         ...subadmin
    //     }
    // ]
  },
  {
    ...testimonial,
  },
  {
    // ...generalsettings,
    title: "General Settings",
    path: "#",
    icon: getIcon("ci:settings"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),

    subNav: [
      {
        ...banner,
      },
      {
        ...logoutusers,
      },
      {
        ...subject,
      },
      {
        ...youtube,
      },
      {
        ...telegram,
      },

      {
        ...category,
      },

      {
        ...subcategory,
      },
      {
        ...contactus,
      },
      {
        // ...helpandsupport
        title: "Help and Support",
        path: "/dashboard/message",
        navicon: "ic:baseline-support-agent",
        cName: "sub-nav",
      },
    ],
  },
  {
    // ...generalsettings,
    // title: 'Quiz',
    // path: '#',
    // icon: getIcon('ci:settings'),
    // dropIcon: getIcon('gridicons:dropdown'),
    // upIcon: getIcon('mi:caret-up'),
  },
];
