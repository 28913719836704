import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import {
  Card,
  Stack,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@mui/material";
import { DataGrid, GridToolbarExport, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DialogUserTable from "./DialogUserTable";

//* custom hook for debouncing
const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue.trim());
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};

export default function UserTableCard() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [statusUser, setStatusUser] = useState();
  const [statusStatus, setStatusStatus] = useState();
  const [verificationStatus, setVerificationStatus] = useState();
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(0);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    // status: '',
    pin_code: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [studentId, setStudentId] = useState();

  const handleSubmit = async (e) => {
    // console.log("hello");
    e.preventDefault();
    const token = localStorage.getItem("token");
    // Handle form submission here, e.g., update data
    console.log("Form submitted:", formData);

    const response = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-admin-user/${studentId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      }
    );
    if (response.ok) {
      sessionStorage.clear();
      console.log("response", response);
      console.log("formdata", formData);
      toast.success("Update Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }

    if (!response?.ok) {
      throw new Error("Network response was not ok");
    }

    // useToast.success("updated");
    // Reset form data
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      mobile_no: "",
      // status: '',
      pin_code: "",
    });
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setTimeout(() => {
      setOpen1(false);
    }, 500);
  };

  //! pagination model
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [totalUsers, setTotalUsers] = useState();
  const [dataLoading, setDataLoading] = useState();
  const debouncedSearchTerm = useDebouncedValue(searchText, 1000);

  const handlePaginationModelChange = (model) => {
    setPaginationModel(model);
  };

  //* below useEFfect will run whenever the debounced term will get change (in current scenario its almost after 1000 sec)
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    setDataLoading(true);

    (async () => {
      try {
        if (debouncedSearchTerm === "") {
          fetchData();
          return;
        }

        const cachedData = JSON.parse(
          sessionStorage.getItem(
            `user-list?limit=${paginationModel.pageSize}&page=1&search=${debouncedSearchTerm}`
          )
        );
        if (cachedData) {
          setUsers(cachedData?.data);
          setTotalUsers(cachedData?.total);
          setDataLoading(false);
          return;
        }

        const token = localStorage.getItem("token");

        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-user-list?limit=${paginationModel.pageSize}&page=1&search=${debouncedSearchTerm}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }, // Link the signal
          { signal }
        );
        setDataLoading(false);

        if (response.data.response_code === 200) {
          // console.log(response?.data);
          setUsers(response?.data?.data?.data);
          setTotalUsers(response?.data?.data?.total || 0);
          sessionStorage.setItem(
            `user-list?limit=${paginationModel.pageSize}&page=1&search=${debouncedSearchTerm}`,
            JSON.stringify(response?.data?.data)
          );
          setDataLoading(false);
        } else {
          setDataLoading(false);
          console.error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        setDataLoading(false);
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    })();

    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };
  }, [debouncedSearchTerm]);

  //! below use Effect runs whenever the page change arrow is clicked
  useEffect(() => {
    fetchData();
  }, [paginationModel]);

  const fetchData = async () => {
    setDataLoading(true);
    const cachedData = JSON.parse(
      sessionStorage.getItem(
        `user-list?limit=${paginationModel.pageSize}&page=${
          paginationModel.page + 1
        }&search=${debouncedSearchTerm}`
      )
    );
    if (cachedData) {
      setUsers(cachedData?.data);
      setTotalUsers(cachedData?.total);
      setDataLoading(false);
      return;
    }
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-user-list?limit=${
          paginationModel.pageSize
        }&page=${paginationModel.page + 1}&search=${debouncedSearchTerm}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(response?.data?.data?.data);
      // sessionStorage.setItem(
      //   `user-list?limit=${paginationModel.pageSize}&page=${
      //     paginationModel.page + 1
      //   }&search=${debouncedSearchTerm}`,
      //   JSON.stringify(response?.data?.data)
      // );
      // console.log("users after pagination", users);
      setTotalUsers(response?.data?.data?.total || 0); // Assuming the total number of users is returned by the API
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleClose = () => setOpen(false);

  const handleStatusUpdate = (userId, status) => {
    setStatusUser(userId);
    setField("status");
    setStatusStatus(status);
    setOpen(true);
  };

  const handleVerificationUpdate = (userId, status) => {
    setStatusUser(userId);
    setField("verification");
    setVerificationStatus(status);
    setOpen(true);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target?.value);
  };

  const filteredUsers =
    users?.filter((user) =>
      Object.values(user).some((value) =>
        value?.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    ) || [];

  // const totalUsers = filteredUsers?.length;

  const startIndex = page * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedUsers = filteredUsers?.slice(startIndex, endIndex);
  const [field, setField] = useState("");

  const [studentData, setStudentData] = useState();
  const StudentData = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/view-user/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await fullResponse.json();
      // console.log(response);

      if (
        response?.response_code === "200" &&
        response?.data &&
        response?.data?.user
      ) {
        const userData = response.data.user;
        setStudentData(userData);
        setStudentId(userData.id);
        console.log(userData);

        formData.first_name = userData?.first_name;
        formData.last_name = userData?.last_name;
        formData.email = userData?.email;
        formData.mobile_no = userData?.mobile_no;
        // formData.status = userData?.status;
        formData.pin_code = userData?.pin_code;
      } else {
        console.error("Error fetching user data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const columns = [
    { field: "id", headerName: "Enrollment No.", width: 150 },
    {
      field: "first_name",
      headerName: "Name",
      width: 200,

      renderCell: (params) => (
        <Link
          style={{
            textDecoration: "none",
            cursor: "pointer",
            fontSize: "",
            color: "#000",
          }}
          to={`/dashboard/students/studentsProfile/${params.id}`}
        >
          {params?.row.first_name + " " + params?.row?.last_name || "NA"}
        </Link>
      ),
    },
    { field: "mobile_no", headerName: "Mobile No.", width: 200 },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      valueGetter: ({ value, row }) => {
        return row?.email || "NA";
      },
    },
    {
      field: "Exam Name",
      headerName: "Exam Name / Class",
      width: 250,
      valueGetter: ({ value, row }) => {
        return row?.exam_name || row?.class_name || "NA";
      },
    },
    {
      field: "created_at",
      headerName: "Registered On",
      width: 250,
      valueGetter: ({ value }) => {
        if (!value) return "NA";
        // Convert the UTC time to IST
        const istTime = moment.tz(value, "UTC").tz("Asia/Kolkata");
        // Format the IST time
        return istTime.format("YYYY-MM-DD HH:mm:ss");
        // return `${istTime.slice(0,10) } ${istTime.slice(11,19)}`;
      },
    },
    { field: "user_from", headerName: "Device", width: 120 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color={params.value ? "success" : "error"}
          onClick={() => handleStatusUpdate(params.id, params.value)}
        >
          {params.value ? "Active" : "InActive"}
        </Button>
      ),
    },
    {
      field: "view",
      headerName: "View Student",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={() =>
            navigate(`/dashboard/students/studentsProfile/${params.id}`)
          }
        >
          view
        </Button>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              handleClickOpen1();
              StudentData(params?.id);
            }}
          >
            Edit
          </Button>
        </>
      ),
    },
    {
      field: "is_otp_verified",
      headerName: "Mobile Verification",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color={params.value ? "success" : "error"}
          onClick={() => handleVerificationUpdate(params.id, params.value)}
        >
          {params.value ? "Verified" : "Not Verfied"}
        </Button>
      ),
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 150,
    //   renderCell: (params) => (
    //     <Button
    //       variant="outlined"
    //       size="small"
    //       color="error"
    //       onClick={() =>
    //         navigate(`/dashboard/students/studentsProfile/${params.id}`)
    //       }
    //     >
    //       View
    //     </Button>
    //   ),
    // },
  ];

  const CustomExportToolbar = () => (
    <div>
      <GridToolbarExport />
    </div>
  );

  return (
    <>
      {Array.isArray(users) ? (
        <Card>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            mb={3}
            px={5}
          >
            {/* <Typography
                variant="h3"
                gutterBottom
                sx={{ marginBottom: { xs: 2, sm: 0 } }}
              >
                All Users
              </Typography> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: { xs: 2, sm: 0 },
                marginLeft: "auto",
              }}
            >
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                value={searchText}
                onChange={handleSearchChange}
                sx={{
                  width: "250px",
                  height: "20px",
                  marginY: "1rem",
                  "& label": {
                    color: "gray",
                  },
                  "& fieldset": {
                    borderColor: "gray",
                  },
                }}
              />
            </div>
          </Stack>
          {/* <SearchNotFound */}

          <DataGrid
            rows={users}
            columns={columns}
            rowCount={totalUsers}
            pageSizeOptions={[5, 10, 20, 25, 50]}
            pagination
            paginationModel={paginationModel}
            loading={dataLoading}
            onPaginationModelChange={handlePaginationModelChange}
            paginationMode="server"
            style={{
              marginLeft: "30px",
              marginTop: "10px",
              marginBottom: "30px",
              border: "none",
            }}
            components={{
              Toolbar: GridToolbar,
              ExportToolbar: CustomExportToolbar,
            }}
          />

          {open1 && (
            <>
              <>
                <Dialog
                  open={true}
                  onClose={handleClose1}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Update User's Details"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Please update the following information:
                    </DialogContentText>
                    <form onSubmit={handleSubmit}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="first_name"
                        name="first_name"
                        label="First Name"
                        type="text"
                        fullWidth
                        value={formData.first_name}
                        onChange={handleChange}
                      />
                      <TextField
                        margin="dense"
                        id="last_name"
                        name="last_name"
                        label="Last Name"
                        type="text"
                        fullWidth
                        value={formData.last_name}
                        onChange={handleChange}
                      />
                      <TextField
                        margin="dense"
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <TextField
                        margin="dense"
                        id="mobile_no"
                        name="mobile_no"
                        label="Mobile Number"
                        type="tel"
                        fullWidth
                        value={formData.mobile_no}
                        onChange={handleChange}
                      />
                      {/* <TextField
                      margin="dense"
                      id="status"
                      name="status"
                      label="Status"
                      select
                      fullWidth
                      value={formData.status}
                      onChange={handleChange}
                    >
                      <MenuItem value="1">Active</MenuItem>
                      <MenuItem value="2">Inactive</MenuItem>
                    </TextField> */}
                      <TextField
                        margin="dense"
                        id="pin_code"
                        name="pin_code"
                        label="Pincode"
                        type="text"
                        fullWidth
                        value={formData.pin_code}
                        onChange={handleChange}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="medium"
                        style={{
                          margin: "1rem",
                        }}
                        onClick={() => {
                          handleClose1();
                        }}
                      >
                        Update
                      </Button>
                    </form>
                  </DialogContent>
                </Dialog>
              </>
            </>
          )}
        </Card>
      ) : (
        <div
          sx={{
            height: "full",
            width: "full",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress
            sx={{ marginY: "auto", display: "flex", marginX: "auto" }}
          />
        </div>
      )}

      {open && (
        <DialogUserTable
          open={open}
          handleClose={handleClose}
          field={field}
          userId={statusUser}
          statusStatus={statusStatus}
          verificationStatus={verificationStatus}
        />
      )}

      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
