import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, Skeleton } from "@mui/material";
import { Container, Typography } from "@mui/material";
import Page from "../components/Page";
import AppWidgetSummary from "../sections/@dashboard/app/AppWidgetSummary";
import NewlyResitered from "../sections/@dashboard/app/NewlyResitered";

export default function DashboardApp() {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [batches, setBatches] = useState([]);
  const [users, setUsers] = useState([]);
  // const [teachers, setTeachers] = useState([]);
  // const [testSeries, setTestSeries] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [dashboard, setDashboard] = useState();
  const role = localStorage.getItem("role");
  const teacherId = localStorage.getItem("id");

  useEffect(() => {
    const token = localStorage.getItem("token");

    async function fetchData() {
      try {
        const usersResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-admin-dashboard`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const usersData = await usersResponse.json();
        setDashboard(usersData?.data);
        console.log(usersData?.data);
        setUsers(usersData?.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }

    fetchData();
  }, [role, teacherId]);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h3" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            {loading ? (
              <Skeleton variant="rectangular" width={225} height={230} />
            ) : (
              <AppWidgetSummary
                title={
                  role === "Admin" ? "All Courses" : "All Assigned Batches"
                }
                total={
                  role === "Admin" ? dashboard?.course : teacherData?.length
                }
                icon={"eva:shopping-bag-fill"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {loading ? (
              <Skeleton variant="rectangular" width={225} height={230} />
            ) : (
              <AppWidgetSummary
                title={role === "Admin" ? "All Users" : "All Resources"}
                total={role === "Admin" ? dashboard?.user : 0}
                color="info"
                icon={"eva:people-fill"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {loading ? (
              <Skeleton variant="rectangular" width={225} height={230} />
            ) : (
              <AppWidgetSummary
                title="All Teachers"
                total={dashboard?.teacher}
                color="warning"
                icon={"fa-solid:chalkboard-teacher"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {loading ? (
              <Skeleton variant="rectangular" width={225} height={230} />
            ) : (
              <AppWidgetSummary
                title="All Test Series"
                color="error"
                total={dashboard?.testseries}
                icon={"eva:file-text-fill"}
              />
            )}
          </Grid>

          {role !== "Teacher" && (
            <Grid item xs={12} md={12} lg={12}>
              {loading ? (
                <Skeleton variant="rectangular" width={1150} height={300} />
              ) : (
                <NewlyResitered usersData={dashboard?.users} />
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
