import React, { useState, useEffect } from "react";
import {
  Card,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import Page from "../../../../components/Page";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import TablePagination from "@mui/material/TablePagination";
import SyncLoader from "react-spinners/SyncLoader";
import { set } from "lodash";

export default function ReportCard({ purchaseDetails }) {
  // console.log(purchaseDetails);
  const sortedUsers = [...purchaseDetails].sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(5);

  const [page, setPage] = useState(0);
  const [purchaseDetail, setPurchaseDetails] = useState(purchaseDetails);
  // const [loading, setLoading] = useState(true);
  // const [open, setOpen] = useState(false);

  useEffect(() => {
    setPurchaseDetails(purchaseDetails);
    console.log("these are batch details for purchases", purchaseDetails);
  }, []);

  const handleSearchChange = (event) => {
    setSearchText(event.target?.value);
    setPage(0); 
  };

  const filteredUsers =
    purchaseDetail?.filter((user) =>
      Object.values(user).some((value) =>
        value?.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    ) || [];

  const totalUsers = filteredUsers?.length;

  const startIndex = page * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedUsers = filteredUsers?.slice(startIndex, endIndex);

  //   {
  //     id: "dummyTestId123",
  //     user: {
  //       userId: "dummyUserId456",
  //       enrollId: "dummyEnrollId789",
  //       icon: "dummyProfilePhotoUrl",
  //       name: "Dummy User Name",
  //       email: "dummyuser@example.com",
  //       phone: "1234567890",
  //       category: "Dummy Stream Category"
  //     },
  //     value: "Dummy Test Series Name",
  //     amount: "Dummy Amount",
  //     txnId: "DummyTransactionId",
  //     is_paid: true, // or false for dummy data
  //     is_active: true, // or false for dummy data
  //     purchaseAt: "2024-02-29T10:00:00.000Z" // Date and time of purchase
  //   }
  //name phonenumber email orderID purchasedAt amount paid coupon code status Action

  const handleStatusUpdate = () => {};
  const columns = [
    {
      field: "user",
      valueGetter: ({ row }) => {
        // return row.user.enrollId
        return row.purchase_id;
      },
      headerName: "Enrollment No.",
      width: 200,
    },

    {
      field: "user_name",
      valueGetter: ({ row }) => {
        // return row.user.name
        return row.first_name + " " + row.last_name;
      },
      headerName: "Name",
      width: 200,
    },

    {
      field: "user_phone",
      valueGetter: ({ row }) => {
        // return row.user.phone
        return row.mobile_no;
      },
      headerName: "Phone Number",
      width: 200,
    },

    {
      field: "user_email",
      valueGetter: ({ row }) => {
        // return row.user.email
        return row.email;
      },
      headerName: "Email",
      width: 200,
    },

    {
      field: "txnId",
      valueGetter: ({ row }) => {
        return row.order_id;
      },
      headerName: "Order Id",
      width: 200,
    },
    {
      field: "course",
      valueGetter: ({ row }) => {
        return row?.course_name || row?.testseries_name;
      },
      headerName: "Item Purchased",
      width: 200,
    },
    {
      field: "purchaseAt",
      valueGetter: ({ row }) => {
        // console.log("console",row.purchaseAt.split(" ")[0])
        // const [day, month, year, time] = row?.created_at?.split(/[^\d]+/);
        return row?.purchased_at?.slice(0, 10);
      },
      headerName: "Purchased At",
      width: 150,
    },
    {
      field: "amount",
      type: "number",
      valueGetter: ({ row }) => {
        return row.price;
      },
      headerName: "Amount Paid",
      width: 150,
    },
    {
      field: "couponCode",
      valueGetter: ({ row }) => {
        if (row?.coupon_id) return row?.coupon;
        return "NA";
      },

      headerName: "Coupon Code",
      width: 200,
    },

    // {
    //   field: "is_active",
    //   headerName: "Status",
    //   width: 150,
    //   renderCell: (params) => (
    //     <Button
    //       variant="outlined"
    //       size="small"
    //       color={params?.value ? "success" : "error"}
    //     // onClick={() => handleStatusUpdate(params.id, params.value)}
    //     >
    //       {params?.value ? "Publish" : "Draft"}
    //     </Button>
    //   ),
    // },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 150,
    //   renderCell: (params) => (
    //     <Button
    //       variant="outlined"
    //       size="small"
    //       color="error"
    //       onClick={() =>
    //         navigate(`/dashboard/students/studentsProfile/${params.id}`)
    //       }
    //     >
    //       View
    //     </Button>
    //   ),
    // },
  ];

  const CustomToolbar = () => (
    <div
      style={{
        display: "flex",
        gap: "50px",
        marginBottom: "20px",
        marginTop: "20px",
      }}
    >
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </div>
  );

  const CustomExportToolbar = () => (
    <div>
      <GridToolbarExport />
    </div>
  );

  const handlePageChange = (event, newPage) => setPage(newPage);

  const handleRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target?.value, 10));
    setPage(0); // Reset page when changing rows per page
  };

  return (
    <div>
      <Card>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          mb={3}
          //   mt={3}
          px={5}
        >
          {/* <Typography
                variant="h3"
                gutterBottom
                sx={{ marginBottom: { xs: 2, sm: 0 } }}
              >
                All purchaseDetails
              </Typography> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: { xs: 2, sm: 0 },
              marginLeft: "auto",
            }}
          >
            <TextField
              label="Search by Name"
              variant="outlined"
              size="small"
              value={searchText}
              onChange={handleSearchChange}
              sx={{
                width: "250px",
                height: "20px",
                marginTop: "20px",
                "& label": {
                  color: "black",
                },
                "& fieldset": {
                  borderColor: "black",
                },
              }}
            />
          </div>
        </Stack>
        
        <DataGrid
          // rows={paginatedUsers}
          rows={paginatedUsers.map((row, index) => ({
            ...row,
            uniqueId: `${row.id}_${index}`,
          }))}
          columns={columns}
          getRowId={(row) => `${row.id}_${row.order_id}`}
          hideFooterPagination
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
            setPage(0); // Reset page when changing page size
          }}
          page={page}
          onPageChange={handlePageChange}
          style={{
            marginLeft: "30px",
            marginTop: "10px",
            marginBottom: "30px",
            border: "none",
          }}
          components={{
            Toolbar: CustomToolbar,
            ExportToolbar: CustomExportToolbar,
          }}
        />

        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={totalUsers}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Card>
    </div>
  );
}
