import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFTextField } from "../../../components/hook-form";

import { useTheme } from "@mui/material/styles";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Page from "../../../components/Page";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TagsInput } from "react-tag-input-component";
import "./style.css";
import { getFileUrl } from "../../../utils/firebaseUtils";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const UpdateBatchDetails = () => {
  const [users, usersSet] = useState([]);
  const [subject, setSubject] = useState([]);
  const [allteachers, teacherSet] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subjectList, setsubjectList] = useState([]);

  const [type, setType] = useState(true);
  const [teacher, setteacher] = useState([]);
  const [selected, setSelected] = useState([]);
  const [languages, setLanguages] = useState([]);

  const [streamCategory, setStreamCategory] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState();
  const [subCategories, setSubCategories] = useState([]);

  // combo
  const [combo, setCombo] = useState([]);
  const [comboChecked, setComboChecked] = useState("false");
  const [testSeriesOptions, setTestSeriesOptions] = useState([]);
  const [loadingTestSeries, setLoadingTestSeries] = useState(false);
  const [testSeriesValue, setTestSeriesValue] = useState("");

  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [states, setStates] = useState();
  const [boards, setBoards] = useState();
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState(null);
  const [category, setCategory] = useState();
  const [registrationEndDate, setRegistrationEndDate] = useState();

  const [parameter, setParameter ]= useState({class_id:" " , exam_id:" " , course:" "})

  const navigate = useNavigate();
  const { batchId } = useParams();
  const theme = useTheme();
  const LoginSchema = Yup.object().shape({});
  // //console.log(subjectList)
  const defaultValues = {
    batch_name: "",
    category: "",
    exam_type: "",
    subject: "",
    // teacherEmail: '',
    starting_date: "",
    ending_date: "",
    mode: "",
    materials: "",
    discount: "",
    charges: "",
    description: "",
    // banner: '',
    language: "",
    remark: "",
    validity: "",
    // course_review: '',
    remember: true,
    is_active: "true",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [file, setFile] = useState();
  const [demoVideo, setDemoVideo] = useState([]);

  const changeHandler = async (event) => {
    const url = await getFileUrl(event.target?.files[0]);
    // console.log(url);
    setFile(url);
  };

  const changeHandlerTwo = async (event) => {
    setDemoVideo(event.target?.files);
  };

  //Getting Batch Details
  // let arr = [];
  const [batchDetails, setBatchDetails] = useState();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    let token = localStorage.getItem("token");

    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-course-by-id/${batchId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      console.log(responseJson?.data);
      setBatchDetails(responseJson?.data);
      checkDetails(responseJson?.data);
      setIsActive(responseJson?.data?.course?.is_active);
      setsubjectList(responseJson?.data?.subjects.map((sub) => sub.id));
      setteacher(responseJson?.data?.teachers?.map((teacher) => teacher.id));
      setSelected(responseJson?.data?.course?.demoVideo);

      setCategory(responseJson.data?.course?.course);
      // setSubCategories(responseJson.data?.course?.subCategory);
      setStates(responseJson.data?.course?.state_exam_name);
      setStateExam(responseJson.data?.course?.exam_name);
      setBoards(responseJson.data?.course?.boards_name);
      setClass(responseJson.data?.course?.classes_name);
        setParameter({
          ...parameter,
          exam_id: responseJson.data?.course?.exam_id,
          class_id: responseJson.data?.course?.class_id,
          course: responseJson.data?.course?.course,
        });
   
      // setRegistrationEndDate(responseJson.data?.course?.registration_end_date);
      if (responseJson?.data?.course?.testseries_id) {
        setComboChecked("true");
        setTestSeriesValue(responseJson?.data?.course?.testseries_id);
      }
    }

    async function fetchTags() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-tag-by-course-id/${batchId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      console.log(responseJson?.data);
      setTags(responseJson?.data?.map((tag) => tag?.name));
    }

    fetchUsers();
    fetchTags();
  }, []);

  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {
      const languageData = await axios.post(
        `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (languageData?.status !== 200)
        throw new Error("Failed to fetch languages");

      setLanguages(languageData?.data?.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);

  // //console.log(batchDetails)
  // //console.log(batchDetails?.demoVideo)
  const [fileSelected, setFileSelected] = useState([]);

  const [is_active, setIsActive] = useState();
  //multipple Links Update
  // //console.log(subjectList)
  const [value, setValue] = useState();
  const checkDetails = (batchDetails) => {
    // if (batchDetails?.demoVideo[0]?.DemoVideofileType == "link") {
    //   setType(true);
    // } else {
    //   setType(false);
    // }

    let arr4 = [];
    batchDetails?.demoVideo?.map((i, indx) => arr4.push(i));

    setFileSelected(arr4);
    setIsActive(batchDetails?.is_active);

    setValue((prev) => ({
      ...prev,
      ...batchDetails?.course,
      combo: Boolean(batchDetails?.course?.combo),
    }));
    // batchDetails?.demoVideo?.map((i, indx) => helperArray.push(i))
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-admin`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      teacherSet(responseJson?.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  //Getting category
  //Stream
  useEffect(() => {
    const token = localStorage.getItem("token");
    const batch = "Batch";
    async function fetchUsers() {
      setLoading(false);
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/classroom-subjects`,
        {
          method: "GET",
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resSubject = await res.json();
      setSubject(resSubject.data);
      console.log(resSubject?.data);
      setLoading(false);
      // const fullResponseCategory = await fetch(
      //   `${process.env.REACT_APP_LIVE_URL}/adminPanel/get-subcategory/jWXUL6FI9m8vUuj7KLeFM`,
      //   {
      //     method: "GET",

      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      // const responseJsonCatergory = await fullResponseCategory.json();
      // setStreamCategory(responseJsonCatergory.data);
      // setLoading(false);
    }

    fetchUsers();
  }, []);

  const handleCategoryChange = async (event) => {
    const selectedCategoryId = event.target.value;
    console.log(event.target.value);
    setSelectedCategory(selectedCategoryId);

    // Fetch subcategories based on the selected category
    const token = localStorage.getItem("token");
    // const response = await fetch(
    //   `${process.env.REACT_APP_LIVE_URL}/adminPanel/get-subcategory/${selectedCategoryId}`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    // const data = await response.json();
    // setSubCategories(data.data);
  };
  const [focus, setFocused] = React.useState(false);
  const [focusTwo, setFocusedTwo] = React.useState(false);
  const [focusThree, setFocusedThree] = React.useState(false);
  const [focusFour, setFocusedFour] = React.useState(false);

  const [showStartingDate, setShowStartingDate] = React.useState(false);
  const [showEndingDate, setShowEndingDate] = React.useState(false);
  const [showRegistrationEndingDate, setShowRegistrationEndingDate] =
    React.useState(false);
  const [showBanner, setShowBanner] = React.useState(false);
  const [showDemoVideo, setShowDemoVideo] = React.useState(false);

  // let children = teacher.concat(damta);
  // //console.log(damta)

  // //console.log(subjects);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setteacher(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  //delete
  const handleDelete = (id) => {
    setFileSelected([]);

    fileSelected?.map((item, index) => {
      if (item?.fileLoc !== id) {
        setFileSelected((prev) => [...prev, item]);
      }
    });
  };

  const onSubmit = async (data) => {
    console.log(value, "subject", subjectList, teacher, selected, file);
    const payload = {
      ...value,
      course_banner: file || value?.course_banner,
      demoVideo: selected || value?.demoVideo,
      subject: subjectList || value?.subject,
      teachers: teacher || value?.teachers,
      combo: comboChecked === "true" ? "true" : "false",
      testseries_id: testSeriesValue,
      registration_end_date: data?.registration_end_date,
      tags: [...tags],
    };
    console.log("payload--->>>>>>>>>>", payload);

    let token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .put(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-course/${batchId}`,
        payload,
        config
      )
      .then((response) => {
        console.log(response);
        if (response?.data?.response_code === 200) {
          reset();
          setLoading(false);
          toast.success(response?.data?.message);
          setTimeout(() => {
            navigate("/dashboard/batches");
            // window.location.reload()
          }, 1000);
        } else {
          toast.error(response?.data?.message);
          setLoading(false);
        }
      })
      .catch((e) => {
        //console.log(e);
        setLoading(false);
        toast.error(e.message);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (comboChecked) {
      // Fetch Test Series details for Combo
      setLoadingTestSeries(true);
      axios
        .get(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-testseries-by-goal?course=${parameter.course}&exam_id=${parameter.exam_id}&class_id=${parameter.class_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setTestSeriesOptions(response.data.data);
          console.log(response.data.data);
          setLoadingTestSeries(false);
        })
        .catch((error) => {
          console.error("Error fetching Test Series details:", error);
          setLoadingTestSeries(false);
        });
    }
  }, [comboChecked]);

  const handleChangeSubject = (event) => {
    const {
      target: { value },
    } = event;
    setsubjectList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onChange2 = (e) => {
    // console.log(e);
    const { name, value } = e.target;
    setValue((prev) => ({ ...prev, [name]: value }));
  };

  // const onChange3 = (content) => {
  //   console.log(content);
  //   // const { name, value } = e;
  //   setValue((prev) => ({ ...prev, description: content }));
  // };

  return (
    <>
      <Page title="Courses">
        <Stack spacing={3} ml={5}>
          <Typography className="page-heading">Update Course </Typography>
          {batchDetails ? (
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3} sx={{ width: "80%" }}>
                <RHFTextField
                  name="name"
                  value={value?.name}
                  onChange={onChange2}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ my: 2 }}
                  spacing={2}
                >
                  {/* <RHFTextField name="category" label={batchDetails?.category?.title ? batchDetails?.category?.title : 'Category'} select>
                                {users?.map((item, index) => {
                                    return <MenuItem key={index} value={item?.title}>{item?.title}</MenuItem>
                                })}
                            </RHFTextField> */}
                </Stack>

                {/*
                <Stack direction={"row"} spacing={3}>
                  <RHFTextField
                    name="category"
                    label="category"
                    sx={{ width: "50%" }}
                    select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  >
                    {streamCategory?.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </RHFTextField>

                  <RHFTextField
                    name="subCategory"
                    label="subcategory"
                    sx={{ width: "50%" }}
                    select
                  >
                    {subCategories.map((subcategory, index) => (
                      <MenuItem key={index} value={subcategory.title}>
                        {subcategory.title}
                      </MenuItem>
                    ))}
                  </RHFTextField>
                </Stack>
                    */}

                <Stack direction="row" spacing={3}>
                  <Stack sx={{ width: "50%" }}>
                    <FormControl>
                      <InputLabel>Subject</InputLabel>
                      <Select
                        label="Subject"
                        multiple
                        name="subject"
                        value={subjectList}
                        onChange={handleChangeSubject}
                        sx={{ width: "100%", color: "black", zIndex: 9 }}
                        MenuProps={MenuProps}
                      >
                        {subject?.map((sub, index) => (
                          <MenuItem
                            key={sub?.id}
                            value={sub?.id}
                            style={getStyles(sub?.name, subject, theme)}
                          >
                            {sub?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack sx={{ width: "50%" }}>
                    <FormControl>
                      <InputLabel>Teacher</InputLabel>
                      <Select
                        label="Teacher"
                        multiple
                        name="teacher"
                        value={teacher}
                        onChange={handleChange}
                        sx={{ width: "100%", color: "black", zIndex: 9 }}
                        MenuProps={MenuProps}
                      >
                        {allteachers?.map((name, index) => {
                          return (
                            <MenuItem
                              key={name}
                              value={name?.id}
                              style={getStyles(name, teacher, theme)}
                            >
                              {name?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>
                </Stack>

                <Stack direction={"row"} spacing={3}>
                  <RHFTextField
                    name="category"
                    label="Category"
                    disabled
                    sx={{ width: "50%" }}
                    // select
                    value={category}
                    // onChange={handleCategoryChange}
                  />

                  {states && (
                    <RHFTextField
                      name="states"
                      label="State"
                      disabled
                      sx={{ width: "50%" }}
                      // select
                      value={states}
                      // onChange={handleStateChange}
                    />
                  )}

                  {boards && (
                    <RHFTextField
                      name="boards"
                      label="Boards"
                      disabled
                      sx={{ width: "50%" }}
                      // select
                      value={boards}
                      // onChange={handleBoardChange}
                    />
                  )}

                  <RHFTextField
                    name="subCategory"
                    label="Subcategory"
                    disabled
                    sx={{ width: "50%" }}
                    value={stateExam || classVal}
                    // select
                    // onChange={handleSubCategoryChange}
                  />
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ my: 2 }}
                  spacing={2}
                >
                  <RHFTextField
                    name="starting_date"
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    InputProps={{
                      classes: {
                        input: "CustomTextField",
                      },
                    }}
                    label={`${
                      value?.starting_date?.split(" ")[0]
                    } (Starting Date)`}
                    onClick={() => setShowStartingDate(true)}
                    type={showStartingDate || focus ? "date" : "text"}
                  />
                  <RHFTextField
                    name="ending_date"
                    onFocus={() => setFocusedTwo(true)}
                    onBlur={() => setFocusedTwo(false)}
                    InputProps={{
                      classes: {
                        input: "CustomTextField",
                      },
                    }}
                    label={`${
                      value?.ending_date
                        ? value.ending_date.split(" ")[0]
                        : "N/A"
                    } (Ending Date)`}
                    onClick={() => setShowEndingDate(true)}
                    type={showEndingDate || focusTwo ? "date" : "text"}
                  />
                  <RHFTextField
                    name="registration_end_date"
                    onFocus={() => setFocusedFour(true)}
                    onBlur={() => setFocusedFour(false)}
                    InputProps={{
                      classes: {
                        input: "CustomTextField",
                      },
                    }}
                    label={`${
                      value?.registration_end_date?.split(" ")[0]
                    } (Registration Ending Date)`}
                    onClick={() => setShowRegistrationEndingDate(true)}
                    type={
                      showRegistrationEndingDate || focusFour ? "date" : "text"
                    }
                  />

                  <RHFTextField
                    name="materials"
                    label={`${value?.materials} (Material Type)`}
                    value={value?.materials}
                    onChange={onChange2}
                    sx={{ width: "100%" }}
                    select
                  >
                    <MenuItem value="Live">Live</MenuItem>
                    <MenuItem value="Recorded">Recorded</MenuItem>
                    <MenuItem value="Both">Both</MenuItem>
                  </RHFTextField>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="flex-start" // Change to flex-start to align inputs at the top
                  justifyContent="space-between"
                  sx={{ my: 2 }}
                  spacing={2}
                >
                  <RHFTextField
                    name="charges"
                    label="Charges"
                    value={Number(value?.charges)}
                    onChange={onChange2}
                    type="number"
                    sx={{ minHeight: "72px" }} // Ensure the same height to align with helperText
                  />
                  <RHFTextField
                    name="discount"
                    label="Discount"
                    value={Number(value?.discount)}
                    onChange={onChange2}
                    type="number"
                    sx={{ minHeight: "72px" }} // Same height as above
                  />
                  {/* <RHFTextField
                  name="bannerfileType"
                  label={batchDetails?.banner[0]?.bannerfileType}
                  select
                >
                  <MenuItem value="image">Image</MenuItem>
                  <MenuItem value="video">Video</MenuItem>
                </RHFTextField> */}
                  <RHFTextField
                    name="banner"
                    onChange={changeHandler}
                    onFocus={() => setFocusedThree(true)}
                    onBlur={() => setFocusedThree(false)}
                    helperText="Image with a size of 712x356 pixels, maintaining a 2:1 aspect ratio (jpeg,jpg,png,webp)"
                    InputProps={{
                      classes: {
                        input: "CustomTextField",
                      },
                    }}
                    label="Banner"
                    onClick={() => setShowBanner(true)}
                    type={showBanner || focusThree ? "file" : "text"}
                    
                    sx={{
                      marginBottom: "-8px", // Adjust margin to align helper text better
                    }}
                    FormHelperTextProps={{
                      sx: {
                        marginTop: "4px", // Reduce space between input and helper text
                      },
                    }}
                  />
                </Stack>
                <Stack>
                  {/* <RHFTextField
                  name="demoVideoType"
                  label={batchDetails?.demoVideo[0]?.DemoVideofileType}
                  sx={{ width: "100%" }}
                  select
                  value={type ? "link" : type === false ? "Video" : "NA"}
                  onChange={(e) => {
                    if (e.target.value === "link") setType(true);
                    else if (e.target.value === "Video") setType(false);
                    else setType(null);
                  }}
                >
                  <MenuItem value="link">Youtube Link</MenuItem>
                  <MenuItem value="Video">Video File</MenuItem>
                  <MenuItem value="NA">NA</MenuItem>
                </RHFTextField> */}
                  {
                    <TagsInput
                      value={selected}
                      onChange={setSelected}
                      name="link"
                      placeHolder="Enter Youtube Links"
                      className="rti--input"
                    />
                  }
                </Stack>
                {/* {console.log(value?.language)} */}
                <RHFTextField
                  name="language"
                  label={"Language"}
                  disabled
                  value={value?.language}
                  onChange={onChange2}
                  sx={{ width: "100%" }}
                  select
                >
                  {languages?.map((language, index) => (
                    <MenuItem key={index} value={language?.medium_code}>
                      {language?.medium_name}
                    </MenuItem>
                  ))}

                  <MenuItem key="both" value={3}>
                    Both
                  </MenuItem>
                </RHFTextField>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ my: 2 }}
                  spacing={2}
                >
                  <RHFTextField
                    name="remark"
                    // label="Remarks"
                    value={value?.remark}
                    onChange={onChange2}
                    type="text"
                  />
                  <RHFTextField
                    name="is_active"
                    label={value?.is_active === "true" ? "Published" : "Draft"}
                    value={value?.is_active}
                    sx={{ width: "100%" }}
                    select
                    onChange={onChange2}
                    // type="checkbox"
                  >
                    <MenuItem
                      value={"true"}
                      // onClick={() => setIsActive("true")}
                    >
                      Publish
                    </MenuItem>
                    <MenuItem
                      value={"false"}
                      // onClick={() => setIsActive("false")}
                    >
                      Draft
                    </MenuItem>
                  </RHFTextField>
                </Stack>

                {/*
                  <Stack direction={"row"} spacing={10}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={comboChecked === "true"}
                          onChange={(event) =>
                            setComboChecked(
                              comboChecked === "true" ? "false" : "true"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Combo"
                    />

                    {comboChecked === "true" &&
                      Array.isArray(testSeriesOptions) &&
                      testSeriesOptions.length > 0 && (
                        <Select
                          label="Test Series"
                          name="testseries_id"
                          fullWidth
                          defaultValue={batchDetails.testseries_id}
                          value={testSeriesValue}
                          onChange={(event) =>
                            setTestSeriesValue(event.target.value)
                          }
                          disabled={loadingTestSeries}
                        >
                          {loadingTestSeries ? (
                            <MenuItem disabled>
                              <CircularProgress size={24} />
                            </MenuItem>
                          ) : (
                            testSeriesOptions?.map((testSeries) => (
                              <MenuItem
                                key={testSeries?.id}
                                value={testSeries?.id}
                              >
                                {testSeries?.name}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      )}
                  </Stack>
               */}

                <Stack>
                  <TagsInput
                    value={tags}
                    onChange={setTags}
                    name="tags"
                    disabled
                    // placeHolder="Enter Tags"
                    className="rti--input"
                  />
                </Stack>

                <RHFTextEditor
                  name="description"
                  value={value?.description}
                  label={"Description"}
                  onChange={(value) => {
                    setValue((prev) => ({ ...prev, description: value }));
                  }}
                />
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
                sx={{
                  bgcolor: "#e6e6ff",
                  color: "black",
                  width: "80%",

                  marginTop: "5%",
                  "&:hover": { background: " #8080ff" },
                }}
              >
                Update
              </LoadingButton>
            </FormProvider>
          ) : (
            <div
              sx={{ width: "full", justifyContent: "center", display: "flex" }}
            >
              <CircularProgress sx={{ display: "flex" }} />
            </div>
          )}
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* <Typography color={positive ? 'green' : "red"} sx={{ textAlign: 'center', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
        </Stack>
      </Page>
    </>
  );
};

export default UpdateBatchDetails;
