import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Popover,
  Stack,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
} from "@mui/material";
import { Skeleton } from "antd";

import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import Page from "../../../components/Page";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const Batch = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const access = JSON.parse(localStorage.getItem("detailObject"));
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPageRows = users
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map((user) => user.id);
      setSelectedRows(currentPageRows);
      // console.log(currentPageRows);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };
  const handleRowSelect = (userId) => {
    if (selectedRows.includes(userId)) {
      setSelectedRows(selectedRows.filter((id) => id !== userId));
    } else {
      setSelectedRows([...selectedRows, userId]);
    }
  };
  const handleDeleteButtonDisabled = () => {
    return selectedRows.length === 0;
  };

  function countUniqueElements(arr) {
    const filteredArray = arr.filter((item) => item !== "");
    const uniqueElements = new Set(filteredArray);
    return uniqueElements.size;
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-courses`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      console.log(responseJson?.data);
      setUsers(responseJson?.data);
      setLoading(false);
    }
    fetchUsers();
  }, []);

  const handleSearchChange = (e) => {
    setSearchValue(e.target?.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenActions = (event, batch) => {
    setAnchorEl(event.currentTarget);
    setSelectedBatch(batch);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
    setSelectedBatch(null);
  };

  const handleView = (batchId) => {
    navigate(`/dashboard/batchDetails/${batchId}`);
  };

  const handleEdit = (batchId) => {
    navigate(`/dashboard/batches/updateBatchDetails/${batchId}`);
  };

  const handleAssignTeacher = (batchId) => {
    navigate(`/dashboard/batches/addTeacherToBatch/${batchId}`);
  };

  const handleDelete = async (batchId) => {
    handleCloseActions(); // Close the popover
    setLoading(true); // Set loading state to true while deleting
    const token = localStorage.getItem("token");
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-course-by-id/${batchId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (res.data?.response_code === 200) {
        toast.success(res.data?.message);
        const updatedUsers = users.filter((user) => user.id !== batchId);
        setUsers(updatedUsers);
      } // Set loading state to false after successful deletion
      // toast.success("Batch Deleted");
      // Refresh data after deletion
    } catch (error) {
      setLoading(false); // Set loading state to false if deletion fails
      toast.error("Some Error occured !!");
      console.error("Error deleting batch:", error);
    }
  };

  const handleUpdateBatchStatus = async (batchId) => {
    handleCloseActions(); // Close the popover
    setLoading(true); // Set loading state to true while updating
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-course-status/${batchId}`,
        { is_active: selectedBatch.is_active == "true" ? "false" : "true" }, // Toggle the status
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false); // Set loading state to false after successful update
      toast.success("Batch status updated");
      // Update the local state with the updated batch status
      const updatedUsers = users?.map((user) =>
        user.id === batchId
          ? { ...user, is_active: String(!(selectedBatch.is_active == "true")) }
          : user
      );
      setUsers(updatedUsers);
    } catch (error) {
      setLoading(false); // Set loading state to false if updating fails
      toast.error("Failed to update batch status");
      console.error("Error updating batch status:", error);
    }
  };

  const handleDeleteSelected = async () => {
    const token = localStorage.getItem("token");
    console.log([...selectedRows]);
    // const res = await axios.delete(
    //   `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-course`,
    //   {
    //     headers: {
    //       content: "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   },
    //   { data: { ids: [...selectedRows] } }
    // );
    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-course`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ids: [...selectedRows] }),
      }
    );
    const fullres = res.json();
    toast.success("Delete successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1500);

    console.log(res);
  };

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <SyncLoader color=" #8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Courses">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            pl={5}
            pr={3}
            marginBottom={"50px"}
          >
            <Typography className="page-heading">All Courses</Typography>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                width: "500px",
                height: "40px",
                // marginBottom: "70px",
                "& label": {
                  color: "black",
                },
                "& fieldset": {
                  borderColor: "black",
                },
              }}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              {selectedRows.length > 0 && (
                <Box height={"40px"}>
                  {/* <Typography>{selectedRows?.length}</Typography> */}
                  <Button
                    variant="contained"
                    color="error"
                    sx={{
                      height: "40px",
                    }}
                    onClick={handleDeleteSelected}
                    // disabled={handleDeleteButtonDisabled()}
                  >
                    Delete Selected Rows
                  </Button>
                </Box>
              )}

              <TextField
                label="Search Course"
                variant="outlined"
                margin="normal"
                value={searchValue}
                onChange={handleSearchChange}
                // sx={{
                //   width: "300px",
                //   height: "20px",
                //   marginBottom: "70px",
                //   "& label": {
                //     color: "black",
                //   },
                //   "& fieldset": {
                //     borderColor: "black",
                //   },
                // }}
              />
            </Stack>
          </Stack>

          <TableContainer component={Paper} pl={5} pr={3} mt={2}>
            <Table>
              <TableHead style={{ backgroundColor: "#e6e6ff" }}>
                <TableRow>
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll}
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < users.length
                    }
                  />
                  <TableCell>Course Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Exam Name / Class</TableCell>
                  <TableCell>Number of Students</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Course Start</TableCell>
                  <TableCell>Course End</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  ?.filter((user) =>
                    user.name.toLowerCase().includes(searchValue.toLowerCase())
                  )
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((user, index) => (
                    <TableRow key={index}>
                      {/* <TableCell> */}
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRows.includes(user.id)}
                          onChange={() => handleRowSelect(user.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          onClick={() => handleView(user.id)}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {user.name}
                        </Typography>
                      </TableCell>
                      {/* </TableCell> */}
                      <TableCell>{user?.course}</TableCell>
                      <TableCell>
                        {user?.exam_name || user?.classes_name || "NA"}
                      </TableCell>
                      <TableCell>
                        {/* {Array.isArray(user?.students)
                          ? user?.students?.length
                          : user?.students?.split(",")?.length || 0} */}
                        {countUniqueElements(user?.students)}
                      </TableCell>
                      <TableCell>{user?.charges || "NA"}</TableCell>
                      <TableCell>
                        {user?.starting_date?.split(" ")[0]}
                      </TableCell>
                      <TableCell>{user?.ending_date?.split(" ")[0]}</TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            padding: "2px 5px",
                            borderRadius: "8px",
                            color: user.is_active == "true" ? "green" : "red",
                          }}
                        >
                          {user.is_active == "true" ? "Published" : "Draft"}
                        </Typography>
                      </TableCell>
                      {/* //!temperory actions commented */}
                      <TableCell>
                        <Box>
                          <MoreVertIcon
                            sx={{
                              color: "",
                              background: "rgba(255, 252, 252, 0.72)",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}
                            onClick={(event) => handleOpenActions(event, user)}
                            aria-describedby={`popover-${index}`}
                          />
                          <Popover
                            id={`popover-${index}`}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleCloseActions}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <>
                              <Typography
                                sx={{
                                  padding: "8px 16px",
                                  cursor: "pointer",
                                  "&:hover": {
                                    backgroundColor: "#e6e6ff",
                                  },
                                }}
                                onClick={() => handleEdit(selectedBatch.id)}
                              >
                                Edit
                              </Typography>
                              {/*
                                <Typography
                                  sx={{
                                    padding: "8px 16px",
                                    cursor: "pointer",
                                    "&:hover": {
                                      backgroundColor: "#e6e6ff",
                                    },
                                  }}
                                  onClick={() =>
                                    handleAssignTeacher(selectedBatch.id)
                                  }
                                >
                                  Assign Teacher
                                </Typography>
                             */}
                              {selectedBatch && (
                                <Typography
                                  sx={{
                                    padding: "8px 16px",
                                    cursor: "pointer",
                                    "&:hover": {
                                      backgroundColor: "#e6e6ff",
                                    },
                                    display:
                                      selectedBatch.is_active == "true"
                                        ? "none"
                                        : "block",
                                  }}
                                  onClick={() => handleDelete(selectedBatch.id)}
                                >
                                  Delete
                                </Typography>
                              )}
                              {selectedBatch && (
                                <Typography
                                  sx={{
                                    padding: "8px 16px",
                                    cursor: "pointer",
                                    "&:hover": {
                                      backgroundColor: "#e6e6ff",
                                    },
                                  }}
                                  onClick={() =>
                                    handleUpdateBatchStatus(selectedBatch.id)
                                  }
                                >
                                  {selectedBatch.is_active == "true"
                                    ? "Move to Draft"
                                    : "Publish"}
                                </Typography>
                              )}
                            </>
                          </Popover>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={users?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>

          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Page>
      )}
    </>
  );
};

export default Batch;
