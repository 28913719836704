import {
  Box,
  Button,
  Collapse,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import * as React from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";

import axios from "axios";

import FileCopyIcon from "@mui/icons-material/FileCopy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserMoreMenu } from "../../user";

const AccordionStyle = {
  // boxShadow: '1px 1px 7px 1px rgba(128,128,128,0.4)',
  background: "#e6e6ff",
  borderRadius: "18px",
  width: "100%",
  transition: "all 0.3s",
  position: "relative",
  transition: "all 0.2s",
  "&:hover": {
    background:
      "#b3b3ff",
  },
};
const TestCard = ({ data, setLoading, loading, testSeriesId }) => {
  const navigate = useNavigate();
  const [showAccordian, setShowAccordian] = React.useState(false);
  const [openCo, setOpen] = React.useState(false);
  const handleClickCollapse = () => {
    setOpen(!openCo);
  };

  // console.log(data)

  return (
    <Box sx={{ marginBottom: "25px", ...AccordionStyle }}>
      <Box
        onClick={() => {
          setShowAccordian(!showAccordian);
          handleClickCollapse();
        }}
        sx={{
          cursor: "pointer",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          position: "relative",
          height: "120px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          sx={{ width: "95%" }}
        >
          <Stack direction="column" justifyContent="space-around">
            <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
              {data?.title}
            </Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
              {data?.Test_code}
            </Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
              Created On:-{" "}
              <span style={{ fontWeight: "400" }}>{data?.created_at.split('T')[0]}</span>
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} width={"100%"} justifyContent={"end"}>
            {/* {data?.question_paper_type === "objective" ? ( */}
            <Button
              variant="contained"
              sx={{
                minWidth: "25%",
                maxWidth: "25%",
                alignSelf: "center",
                background: "#e6e6ff",
                color: "black",
                borderRadius: "20px",
                "&:hover": { background: " #8080ff" },
              }}
              onClick={() =>
                navigate(`/dashboard/quiz/${data?.title}/${data?.id}`)
              }
            //   `/dashboard/quiz/${data?.quiz_title}/${data?.id}`
            >
              Questions
            </Button>
            {/* ) : (
            ""
          )} */}

            {(
              <Button
                variant="contained"
                sx={{
                  minWidth: "30%",
                  maxWidth: "30%",
                  alignSelf: "center",
                  background: "#e6e6ff",
                  color: "black",
                  borderRadius: "20px",
                  "&:hover": { background: " #8080ff" },
                }}
                onClick={() =>
                  navigate(
                    `/dashboard/quiz/quizStudents/${data?.title}/${data?.id}`
                  )
                }
              //   `/dashboard/quiz/${data?.quiz_title}/${data?.id}`
              >
                O-Result
              </Button>
            )}
            {/* { (
            <Button
              variant="contained"
              sx={{
                width: "15%",
                alignSelf: "center",
                background: "#e6e6ff",
                color: "black",
                borderRadius: "20px",
                "&:hover": { background: " #8080ff" },
              }}
              onClick={() =>
                navigate(
                  `/dashboard/test/testSeriesDetails/attemptedTest/${data?.id}`
                )
              }
              //   `/dashboard/quiz/${data?.quiz_title}/${data?.id}`
            >
              OMR
            </Button>
          )
          //  : ( <></>
            //! temperory comment on 21st may
            // <Button
            //   variant="contained"
            //   sx={{
            //     width: "15%",
            //     alignSelf: "center",
            //     background: "#e6e6ff",
            //     color: "black",
            //     borderRadius: "20px",
            //     "&:hover": { background: " #8080ff" },
            //   }}
            //   onClick={() =>
            //     navigate(
            //       `/dashboard/test/testSeriesDetails/attemptedTest/${data?.id}`
            //     )
            //   }
            //   //   `/dashboard/quiz/${data?.quiz_title}/${data?.id}`
            // >
            //   Result sheet
            // </Button>
          // ) 
          } */}
          </Stack>
        </Stack>
      </Box>

      <Collapse in={openCo} timeout="auto" unmountOnExit>
        <Box
          p={2}
          sx={{
            bgcolor: "#e6e6ff",
            borderRadius: "0 0 18px 18px",
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                Total Questions:-{" "}
                <span style={{ fontWeight: "400" }}>
                  {data?.questions?.length || 0}
                </span>
              </Typography>
              {/* //! commented on 17th may because below fields are not sent from backend from the new API */}
              {/* <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                Total Marks:-{" "}
                <span style={{ fontWeight: "400" }}>{data?.totalMarks}</span>
              </Typography> */}
              {/* <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                Type:-{" "}
                <span style={{ fontWeight: "400" }}>
                  {data?.question_paper_type}
                </span>
              </Typography> */}
            </Stack>

            <Stack direction="column">
              {/* <Stack direction="row" spacing={2}>
                <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                  Question Paper:
                </Typography>
                <a href={data?.question_paper?.fileLoc}>
                  <FileCopyIcon fontSize="medium" />
                </a>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                  Answer Template:
                </Typography>
                <a href={data?.answer_template?.fileLoc}>
                  <FileCopyIcon fontSize="medium" />
                </a>
              </Stack> */}
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                Negative Marking:-{" "}
                <span style={{ fontWeight: "400" }}>
                  {data?.nagetiveMarking === 'true' ? `Yes (${data?.nagetiveMarks})` : "No"}
                </span>
              </Typography>
            </Stack>
          </Stack>
          <Typography sx={{ fontSize: "16px", fontWeight: "600", marginY: "1rem" }}>
            Instructions:-{" "}
            <div style={{ fontWeight: "400", marginY: "0.25rem" }} dangerouslySetInnerHTML={{ __html: data?.instructions || "NA" }}></div>
          </Typography>
        </Box>
      </Collapse>
      <Box sx={{ position: "absolute", right: "1%", top: "42px" }}>
        <UserMoreMenu
          title="TestDetails"
          req="delete-test"
          id={data?.id}
          setLoading={setLoading}
          testSeriesId={testSeriesId}
          testId={data?.id}
        />
      </Box>
    </Box>
  );
};

export default TestCard;
