import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Card,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl
} from "@mui/material";
import Page from "../components/Page";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import axios from "axios";

import TablePagination from "@mui/material/TablePagination";

import SyncLoader from "react-spinners/SyncLoader";
import { ToastContainer, toast } from "react-toastify";
import { CSVLink } from "react-csv";
import ReportCard from "../sections/@dashboard/user/Components/ReportCard";
import UserTableCard from "../sections/@dashboard/user/Components/UserTableCard";

export default function Student() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [users, setUsers] = useState([]);
  const [purchaseDetails, setPurchaseDetails] = useState();
  const [seriesDetails, setSeriesDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [download, setDownload] = useState(true);
  const [statusUser, setStatusUser] = useState();
  const [statusStatus, setStatusStatus] = useState();
  const [errorOTP, setErrorOtp] = useState("");
  const [showDownload, setShowDownload] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [type, setType] = useState('user');
  const [apiPage, setApiPage] = useState(1);

  useEffect(() => {
    

    const fetchPurchaseDetail = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/purchase-report?page=${apiPage}&limit=${500}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("data of Purchase Details from new api", response?.data)
        setPurchaseDetails(response.data.data);
        setLoading(false);

        if (response?.data?.msg === "not an admin") {
          localStorage.removeItem("isLoggedIn");
          localStorage.removeItem("details");
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }


    // fetchUsers();
    // fetchUsers2();
    // handleFilter("batch", "all");

    fetchPurchaseDetail();

    return () => {

    }


  }, [apiPage, navigate]);




  const handleStatusUpdate = (userId, status) => {
    setStatusUser(userId);
    setStatusStatus(status);
    setOpen(true);
  };



  const filteredUsers =
    users?.filter((user) =>
      Object.values(user).some((value) =>
        value?.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    ) || [];

  const totalUsers = filteredUsers?.length;

  const startIndex = page * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedUsers = filteredUsers?.slice(startIndex, endIndex);

  const columns = [

    {
      field: "StudentName",
      headerName: "Name",
      width: 200,
      renderCell: (params) => (
        <Link
          onClick={() =>
            navigate(`/dashboard/students/studentsProfile/${params?.id}`)
          }
        >
          {params?.value}
        </Link>
      ),
    },
    { field: "id", headerName: "Enrollment No.", width: 150 },
    { field: "mobile_no", headerName: "Phone No.", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "signinType", headerName: "Auth Type", width: 100 },
    { field: "user_from", headerName: "Device", width: 100 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color={params.value ? "success" : "error"}
          onClick={() => handleStatusUpdate(params?.id, params?.value)}
        >
          {params.value == 1 ? "Active" : "InActive"}
        </Button>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={() =>
            navigate(`/dashboard/students/studentsProfile/${params?.id}`)
          }
        >
          View
        </Button>
      ),
    },
  ];

  

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <SyncLoader
            color=" #8080ff"
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <Page title="Students" className="px-2">
          {/* //!here is the card below  */}
          <FormControl variant="standard" className="">
            <Select
              style={{
                fontSize: "1.875rem",
                lineHeight: "2.25rem",
                fontWeight: "600",
                letterSpacing: "0.025em",
                margin: "2rem",
                borderStyle: "none",
                outline: "2px solid transparent",
                minWidth: "18rem"


              }}
              className="text-3xl  mx-8  font-semibold tracking-wide  border-none outline-none my-8 focus:border-none focus:outline-none"
              // labelId="demo-simple-select-standard-label"
              // id="demo-simple-select-standard"
              value={type}
              onChange={(e) => setType(e.target?.value)}
            // label="Courses"
            >

              <MenuItem value="user">Users</MenuItem>
              <MenuItem value="purchases">Purchase Report</MenuItem>
            </Select>
          </FormControl>
          {type == "user" ? <UserTableCard />
            : <ReportCard purchaseDetails={purchaseDetails} />}
          {/* <ReportCard batchDetails={batchDetails}/> */}


          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Page>
      )}
    </>
  );
}
